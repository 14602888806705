import React, { ReactNode } from 'react'
import { Box, styled } from '@mui/material'
import { isPresent } from '../../utils'

interface Props {
  header?: ReactNode
  main: ReactNode
  footer: ReactNode
}

const Main = styled('div')`
  flex-grow: 1;
  overflow: hidden;
`

const Wrapper = styled(Box)`
  height: 100%;
  display: flex;
  flex-direction: column;
`

const Header = styled(Box)`
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
`

const MapLayout = ({ header, main, footer }: Props) => {
  return (
    <Wrapper>
      {isPresent(header) && <Header>{header}</Header>}
      <Main>{main}</Main>
      <Box>{footer}</Box>
    </Wrapper>
  )
}

export default MapLayout
