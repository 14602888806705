import { useParams } from 'react-router'
import { useEffect, useState } from 'react'
import { Queue } from '../model/queue'

export function useQueueIdUrlParam(queues: Queue[]) {
  const queueId: string = useParams().queueId!
  const [selectedQueueIndex, setSelectedQueueIndex] = useState<number>()

  useEffect(() => {
    if (queues.length > 0) {
      const index = queues.findIndex(queue => {
        return queue.id === queueId
      })

      if (index >= 0) {
        setSelectedQueueIndex(index)
        return
      }
    }
    setSelectedQueueIndex(undefined)
  }, [queues, queueId])

  return selectedQueueIndex
}
