import React, { useContext, useRef } from 'react'
import { Button, Paper, styled, TextField, Typography } from '@mui/material'
import { isNotPresent } from '../../common/utils'
import { SecureApiContext } from '../../app/User'
import { useNavigate } from 'react-router'


const Main = styled(Paper)`
  padding: 16px;
`

const StyledForm = styled('form')`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

interface Props {
  redirectUrl?: string
}

export default function Login({ redirectUrl }: Props) {

  const usernameRef = useRef<HTMLInputElement>(null)
  const passwordRef = useRef<HTMLInputElement>(null)
  const navigate = useNavigate()

  const { login } = useContext(SecureApiContext)

  function handleLogin(e: React.FormEvent) {
    e.preventDefault()
    const username = usernameRef.current?.value
    const password = passwordRef.current?.value

    if (isNotPresent(username)) return
    if (isNotPresent(password)) return

    login({ username, password })
      .then(() => navigate(redirectUrl ?? '/'))
  }

  return (
    <Main>
      <Typography variant='h4'>Login</Typography>

      <StyledForm onSubmit={e => handleLogin(e)}>
        <TextField label='Username' variant='standard' inputRef={usernameRef} />
        <TextField label='Password' variant='standard' type='password' inputRef={passwordRef} />
        <Button type='submit' variant='contained'>Login</Button>
      </StyledForm>

    </Main>
  )
}
