import { useEffect, useState } from 'react'
import { Queue } from '../model/queue'

export default function useSelectedQueue(queues: Queue[], selectedQueueIndex: number) {

  const [selectedQueue, setSelectedQueue] = useState<Queue>(queues[selectedQueueIndex])

  useEffect(() => {
    const queue = queues[selectedQueueIndex]

    if (selectedQueue.id !== queue.id) {
      setSelectedQueue(queue)
    }
  })

  return { selectedQueue, setSelectedQueue }
}
