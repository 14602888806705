import React, { ReactNode } from 'react'
import { isNotPresent } from '../utils'
import { Box, styled, Typography } from '@mui/material'

interface Props {
  leftSlot?: ReactNode,
  rightSlot?: ReactNode,
  text?: string,
  variant?: 'h6' | 'body1'
}

const Wrapper = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  div.slot {
    flex: 1 0 0;
    
    &.rightSlot {
      display: flex;
      justify-content: right;
    }
  }
`

export function StandardBar({ leftSlot, rightSlot, text, variant }: Props) {
  if (isNotPresent(rightSlot)) rightSlot = <></>
  if (isNotPresent(leftSlot)) leftSlot = <></>
  if (isNotPresent(text)) text = ''
  if (isNotPresent(variant)) variant = 'body1'

  return (
    <Wrapper>
      <div className='slot'>{leftSlot}</div>
      <Typography variant={variant}>{text}</Typography>
      <div className='slot rightSlot'>{rightSlot}</div>
    </Wrapper>
  )
}
