import React, { useContext } from 'react'
import { Navigate, Outlet, Route, Routes } from 'react-router-dom'
import { QueueViewerPage } from '../../modules/queue/QueueViewerPage'
import QueueEditListPage from '../../modules/queue/QueueEditListPage'
import QueueEditorPage from '../../modules/queue/QueueEditorPage'
import useQueues from '../../modules/queue/hooks/useQueues'
import { AdminModeContext } from '../User'

interface Props {

}

export function QueueRouting({}: Props) {
  const { queues, addQueue, updateQueue, deleteQueue, addQueueEnd } = useQueues()

  const isInAdminMode = useContext(AdminModeContext)

  return (
    <Routes>
      <Route path='queues' element={<Outlet />}>
        <Route index element={<QueueViewerPage queues={queues}
                                               addQueueEnd={addQueueEnd} />} />
        <Route path=':queueId' element={<QueueViewerPage queues={queues}
                                                         addQueueEnd={addQueueEnd} />} />

        <Route path={'*'} element={<Navigate to='/queues' />} />
      </Route>

      <Route path='editor' element={isInAdminMode ? <Outlet /> : <Navigate to='/queues' />}>
        <Route index element={<QueueEditListPage queues={queues}
                                                 addQueue={addQueue}
                                                 deleteQueue={deleteQueue} />} />
        <Route path=':queueId' element={<QueueEditorPage queues={queues}
                                                         updateQueue={updateQueue} />} />
      </Route>
    </Routes>
  )
}
