import React from 'react'
import { Alert, Typography } from '@mui/material'
import { isPresent, valueOrDefault } from '../../utils'
import { Message } from './MessageProvider'


export function MessageView({ firstLine, secondLine, severity, onClose }: Message) {
  const $severity = valueOrDefault(severity, 'info')

  return (
    <>
      <Alert
        onClose={onClose}
        severity={$severity}
        variant='filled'
        elevation={6}
        sx={{ width: '100%' }}
      >
        {isPresent(firstLine) && <Typography>{firstLine}</Typography>}
        {isPresent(secondLine) && <Typography fontSize='small'>{secondLine}</Typography>}
      </Alert>
    </>
  )
}
