import { useContext, useEffect, useState } from 'react'
import { Leaderboard } from '../model/Leaderboard'
import { serverBaseUrl } from '../../../app/base'
import { SecureApiContext } from '../../../app/User'

export function useLeader() {

  const { secureApi } = useContext(SecureApiContext)

  const [leaderboard, setLeaderboard] = useState<Leaderboard>()


  useEffect(() => {
    secureApi.get<Leaderboard>(serverBaseUrl + '/leaderboard')
      .then(({ data }) => setLeaderboard(data))
  }, [])

  return leaderboard
}
