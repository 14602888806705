export interface Vector2DData {
  readonly x: number
  readonly y: number
}

export class Vector2D implements Vector2DData {
  constructor(readonly x: number, readonly y: number) {
  }

  static fromVector(vector: Vector2D) {
    return new Vector2D(vector.x, vector.y)
  }

  toData(): Vector2DData {
    return { x: this.x, y: this.y }
  }

  dot(other: Vector2D) {
    return this.x * other.x + this.y * other.y
  }

  getLength() {
    return Math.sqrt(this.dot(this))
  }

  normalize() {
    const length = this.getLength()
    return new Vector2D(this.x / length, this.y / length)
  }

  add(other: Vector2D) {
    return new Vector2D(this.x + other.x, this.y + other.y)
  }

  subtract(other: Vector2D) {
    return new Vector2D(this.x - other.x, this.y - other.y)
  }

  scale(a: number) {
    return new Vector2D(a * this.x, a * this.y)
  }

  rotate90Left() {
    return new Vector2D(-this.y, this.x)
  }

  lerp(other: Vector2D, t: number) {
    return this.scale(1 - t).add(other.scale(t))
  }

  getSquareDistance(other: Vector2D) {
    const sub = this.subtract(other)
    return sub.dot(sub)
  }

  getDistance(other: Vector2D) {
    return Math.sqrt(this.getSquareDistance(other))
  }
}
