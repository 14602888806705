import React from 'react'
import { Accordion, AccordionDetails, AccordionSummary, Box, Tab, Tabs, Typography } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import MoreVert from '@mui/icons-material/MoreVert';
import { StandardContent } from '../../../common/components/StandardContent'
import { isAppleMobile } from '../../../common/utils'


interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ padding: '16px 4px' }}>
          {children}
        </Box>
      )}
    </div>
  )
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

export function InstallContent() {

  const initialTabValue = isAppleMobile() ? 0 : 1

  const [tabValue, setTabValue] = React.useState(initialTabValue)

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue)
  }


  return (
    <StandardContent noVerticalPadding>

      <img style={{ width: '40%', display: 'block', marginLeft: 'auto', marginRight: 'auto', paddingTop: '16px' }}
           src='/res/images/q-line-app-logo.png' alt='' />

      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={tabValue} onChange={handleChange} aria-label='basic tabs example' variant='fullWidth'>
          <Tab label='iPhone' {...a11yProps(0)} />
          <Tab label='Android' {...a11yProps(1)} />
        </Tabs>
      </Box>
      <TabPanel value={tabValue} index={0}>
        <>
          <Typography>Q-Line ganz einfach als App auf deinem iPhone installieren:</Typography>
          <br/>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls='panel1a-content'
              id='panel1a-header'
            >
              <Typography>1. <img style={{ width: '10%' }} src='/res/images/apple-share.png' alt='Share' /> in der Browserleiste drücken.</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <img style={{ width: '100%' }} src='/res/images/apple-share-1.jpg' alt='' />
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls='panel2a-content'
              id='panel2a-header'
            >
              <Typography>2. Etwas nach unten scrollen und "Zum Home-Bildschirm" wählen.</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <AccordionDetails>
                <img style={{ width: '100%' }} src='/res/images/apple-share-2.jpg' alt='' />
              </AccordionDetails>
            </AccordionDetails>
          </Accordion>
        </>
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
        <>
          <Typography>Q-Line ganz einfach als App auf deinem Android installieren:</Typography>
          <br/>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls='panel1a-content'
              id='panel1a-header'
            >
              <Typography>1. <MoreVert/> in der Browserleiste drücken.</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <img style={{ width: '100%' }} src='/res/images/android-share-1.jpeg' alt='' />
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls='panel2a-content'
              id='panel2a-header'
            >
              <Typography>2. "Zum Startbildschrim hinzufügen" oder "Installieren" drücken.</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <AccordionDetails>
                <img style={{ width: '100%' }} src='/res/images/android-share-2.jpeg' alt='' />
              </AccordionDetails>
            </AccordionDetails>
          </Accordion>
        </>
      </TabPanel>
    </StandardContent>
  )

}
