import { BezierCubic } from './BezierCubic'
import { Vector2D } from './Vector2D'
import { BezierWithDistanceAndT, CurvePoint } from './GeometryData'

export class CurveUtils {

  static getClosestTOfClosestBezier(vector: Vector2D, beziers: BezierCubic[]): CurvePoint {
    const bezierWithDistanceAndT = beziers.reduce<BezierWithDistanceAndT>((acc, current, index) => {
      const currentClosest = current.getClosestDistanceWithT(vector)
      return currentClosest.distance < acc.distanceWithT.distance
        ? { bezierIndex: index, distanceWithT: currentClosest }
        : acc
    }, { bezierIndex: -1, distanceWithT: { distance: Infinity, t: 0 } })

    return { bezierIndex: bezierWithDistanceAndT.bezierIndex, t: bezierWithDistanceAndT.distanceWithT.t }
  }

  static getVectorAt(curvePoint: CurvePoint, beziers: BezierCubic[]) {
    return beziers[curvePoint.bezierIndex].at(curvePoint.t)
  }

  static getCurvePointFrom(curveT: number): CurvePoint {
    const a = Math.floor(curveT)
    const b = curveT - a

    if (a > 0 && b === 0) {
      return { bezierIndex: a - 1, t: 1 }
    }
    return { bezierIndex: a, t: b }
  }

  static getCurveTFrom(curvePoint: CurvePoint) {
    return curvePoint.bezierIndex + curvePoint.t
  }

  static getMaxCurveT(beziers: BezierCubic[]) {
    return beziers.length
  }

  static split(beziers: BezierCubic[], curveT: number): [BezierCubic[], BezierCubic[]] {
    if (beziers.length === 0) return [[], []]

    const curvePoint = this.getCurvePointFrom(curveT)

    const splitBeziers = beziers[curvePoint.bezierIndex].split(curvePoint.t)

    const curve1 = [...beziers.filter((_, index) => index < curvePoint.bezierIndex), splitBeziers[0]]
    const curve2 = [splitBeziers[1], ...beziers.filter((_, index) => index > curvePoint.bezierIndex)]

    return [curve1, curve2]
  }

  static getLengthToCurveT(curveT: number, beziers: BezierCubic[]) {
    return this.getLengthToCurvePoint(this.getCurvePointFrom(curveT), beziers)
  }

  static getLengthToCurvePoint(curvePoint: CurvePoint, beziers: BezierCubic[]) {
    let length = 0
    for (let i = 0; i < curvePoint.bezierIndex; i++) {
      length += beziers[i].getLength()
    }
    length += beziers[curvePoint.bezierIndex].getLengthTo(curvePoint.t)
    return length
  }
}
