import { useContext, useState } from 'react'
import { Queue } from '../model/queue'
import { BezierCubic } from '../../../common/model/geometry/BezierCubic'
import { Vector2D } from '../../../common/model/geometry/Vector2D'
import { isNotPresent, randomId } from '../../../common/utils'
import { serverBaseUrl } from '../../../app/base'
import { SecureApiContext } from '../../../app/User'
import useCallOnce from '../../../common/hooks/useCallOnce'
import { CurvePoint, TimedCurvePoint } from '../../../common/model/geometry/GeometryData'

export default function useQueues() {
  const queuesUrl = `${serverBaseUrl}/queues`

  const { secureApi } = useContext(SecureApiContext)

  const [queues, setQueues] = useState<Queue[]>([])

  useCallOnce(() => {
    getQueues()
    const seconds = 5000
    setInterval(() => getQueues(), seconds)
  })

  function addQueue() {
    const newQueue: Queue = {
      title: 'New Queue',
      velocity: 1,
      velocityOffset: 0,
      path: [],
      location: new Vector2D(0, 0),
      id: randomId(),
    }
    secureApi.post<Queue>(queuesUrl, newQueue)
      .then(({ data }) => {
        setQueues(prevState => [...prevState, data])
      })
  }

  function updateQueue(queue: Queue) {
    const requestQueue = { ...queue, path: queue.path.map(b => b.toData()) }
    secureApi.put<Queue>(queuesUrl, requestQueue)
      .then(({ data }) => {

        data = transformQueue(data)
        setQueues(prevState => prevState.map(
          queue => queue.id === data.id ? data : queue
        ))
      })
  }

  function deleteQueue(queueId: string) {
    secureApi.delete(`${queuesUrl}/${queueId}`)
      .then(() => {
        setQueues(prevState =>
          prevState.filter(queue => queue.id !== queueId))
      })
  }

  function getQueues() {
    secureApi.get<Queue[]>(queuesUrl)
      .then(({ data }) => {
        const qs = data.map(q => transformQueue(q))
        setQueues(qs)
      })
  }

  function addQueueEnd(queueId: string, curvePoint: CurvePoint) {
    secureApi.post<TimedCurvePoint | undefined>(`${queuesUrl}/${queueId}/end`, curvePoint)
      .then(({ data }) => {
        if (isNotPresent(data)) return
        const currentEnd = TimedCurvePoint.fromTimedCurvePoint(data)
        setQueues(prevState => prevState.map(
          queue => queue.id === queueId ? { ...queue, currentEnd } : queue
        ))
      })
  }

  return {
    queues,
    addQueue,
    updateQueue,
    deleteQueue,
    addQueueEnd
  }
}

function transformQueue(queue: Queue) {
  if (queue.currentEnd) {
    queue.currentEnd = TimedCurvePoint.fromTimedCurvePoint(queue.currentEnd)
  }
  queue.path = queue.path.map(bezier => BezierCubic.fromBezier(bezier))

  return queue
}
