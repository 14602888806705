import { Divider, List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router'
import { ChatOutlined, Instagram, PeopleOutline, QrCode2, Share } from '@mui/icons-material'
import { StandardContent } from '../../common/components/StandardContent'
import { StandardHeader } from '../../common/components/StandardHeader'
import { StandardLayout } from '../../common/components/StandardLayout'
import { OverviewContent } from './components/OverviewContent'


export function InformationPage() {
  const navigate = useNavigate()
  const mailLink = 'mailto:feedback@q-line.io?subject=Feedback%20aus%20der%20App'

  function canShare() {
    return Boolean(navigator.share)
  }

  function share() {
    navigator.share({
      title: 'Q-Line',
      url: window.location.host
    })
  }

  return (
    <StandardLayout
      header={<StandardHeader title='Informationen' showBack />}
      main={
        <>

          <OverviewContent />

          <StandardContent noVerticalPadding>
            <List>
              <ListItemButton onClick={() => navigate('./wir-und-das-projekt')}>
                <ListItemIcon><PeopleOutline /></ListItemIcon>
                <ListItemText>Über uns und das Projekt</ListItemText>
              </ListItemButton>
              <Divider />
              <ListItemButton href={mailLink}>
                <ListItemIcon><ChatOutlined /></ListItemIcon>
                <ListItemText>Feedback</ListItemText>
              </ListItemButton>
              <Divider />
              <ListItemButton href='https://www.instagram.com/q_line.io/' target='_blank'>
                <ListItemIcon><Instagram /></ListItemIcon>
                <ListItemText>Instagram</ListItemText>
              </ListItemButton>
              <Divider />

              {
                canShare() && (
                  <>
                    <ListItemButton onClick={share}>
                      <ListItemIcon><Share /></ListItemIcon>
                      <ListItemText>Teilen</ListItemText>
                    </ListItemButton>
                    <Divider />
                  </>
                )
              }

              <ListItemButton onClick={() => navigate('./qr-code')}>
                <ListItemIcon><QrCode2 /></ListItemIcon>
                <ListItemText>QR Code</ListItemText>
              </ListItemButton>
            </List>
          </StandardContent>

          <StandardContent noVerticalPadding>
            <List>
              <ListItemButton onClick={() => navigate('./impressum')}>
                <ListItemText>Impressum</ListItemText>
              </ListItemButton>
              <Divider />
              <ListItemButton onClick={() => navigate('./datenschutz')}>
                <ListItemText>Datenschutz</ListItemText>
              </ListItemButton>
              <Divider />
              <ListItemButton onClick={() => navigate('./agb')}>
                <ListItemText>AGB</ListItemText>
              </ListItemButton>
              <Divider />
              <ListItemButton onClick={() => navigate('./lizenzen')}>
                <ListItemText>Lizenzen</ListItemText>
              </ListItemButton>
            </List>
          </StandardContent>

          <StandardContent noVerticalPadding>
            <List>
              <ListItemButton onClick={() => navigate('./entwickler')}>
                <ListItemText>Entwickler</ListItemText>
              </ListItemButton>
            </List>
          </StandardContent>
        </>
      }
    />
  )
}
