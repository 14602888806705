import React, { ReactNode } from 'react'
import { Box, styled } from '@mui/material'
import { isPresent } from '../utils'

interface Props {
  header?: ReactNode,
  main: ReactNode,
  footer?: ReactNode
}

const Main = styled(Box)`
  flex-grow: 1;
  overflow: auto;
  width: 100%;
`

const Wrapper = styled(Box)`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`

export function StandardLayout({ header, main, footer }: Props) {
  return (
    <Wrapper>
      {isPresent(header) && <Box>{header}</Box>}
      <Main>{main}</Main>
      {isPresent(footer) && <Box>{footer}</Box>}
    </Wrapper>
  )
}
