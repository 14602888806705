import React, { useState } from 'react'
import { Navigate, Outlet, Route, Routes } from 'react-router-dom'
import { InformationPage } from '../../modules/information/InformationPage'
import { PrivacyPage } from '../../modules/information/PrivacyPage'
import { LegalNoticePage } from '../../modules/information/LegalNoticePage'
import { TermsPage } from '../../modules/information/TermsPage'
import { UserRouting } from './UserRouting'
import { useCookies } from 'react-cookie'
import { useNavigate } from 'react-router'
import { StartPage } from '../../modules/information/StartPage'
import { AboutPage } from '../../modules/information/AboutPage'
import { QrCodePage } from '../../modules/information/QrCodePage'
import { LicensesPage } from '../../modules/information/LicensesPage'
import { getBoolValueFromString, getStandardCookieOptions } from '../../common/utils'
import { DeveloperPage } from '../../modules/information/DeveloperPage'

interface Props {

}

type CookieName = 'showLegal' | 'showInstall'

type Cookie = {
  showLegal: boolean,
  showInstall: boolean,
}

export function Routing({}: Props) {

  const navigate = useNavigate()
  const [cookies, setCookie, removeCookie] = useCookies<CookieName, Cookie>()

  const storedShowInstall = getBoolValueFromString(cookies['showInstall']) ?? true
  const storedShowLegal = getBoolValueFromString(cookies['showLegal']) ?? true

  const showInstallOnDevice = storedShowInstall && !window.matchMedia('(display-mode: standalone)').matches

  const [showInstall, setShowInstall] = useState(showInstallOnDevice)
  const [showLegal, setShowLegal] = useState(storedShowLegal)

  const showStart = showLegal || showInstall


  function finish(showLegalAgain: boolean, showInstallAgain: boolean) {
    setShowInstall(false)
    setShowLegal(false)

    setCookie('showInstall', showInstallAgain, getStandardCookieOptions())
    setCookie('showLegal', showLegalAgain, getStandardCookieOptions())
    navigate('/')
  }

  function removeCookies() {
    removeCookie('showInstall')
    removeCookie('showLegal')
    console.log('hier')
  }

  return (
    <Routes>
      <Route index element={<Navigate to='/queues' />} />

      <Route path='/*' element={
        showStart
          ? <Navigate to='/start/0' />
          : <UserRouting />}
      />

      <Route path='start/:stepIndex' element={
        showStart
          ? (
            <StartPage
              showLegal={showLegal}
              showInstall={showInstall}
              finish={finish}
              removeCookies={removeCookies}
            />
          )
          : <Navigate to='/queues' />
      } />

      <Route path='informationen' element={<Outlet />}>
        <Route index element={<InformationPage />} />
        <Route path='wir-und-das-projekt' element={<AboutPage />} />
        <Route path='qr-code' element={<QrCodePage />} />

        <Route path='datenschutz' element={<PrivacyPage />} />
        <Route path='impressum' element={<LegalNoticePage />} />
        <Route path='agb' element={<TermsPage />} />
        <Route path='lizenzen' element={<LicensesPage />} />

        <Route path='entwickler' element={<DeveloperPage />} />
      </Route>

      <Route path={'*'} element={<Navigate to='/queues' />} />
    </Routes>
  )
}
