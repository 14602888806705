import React, { useEffect } from 'react'
import { Box, Paper, styled, Typography } from '@mui/material'
import { useNavigate } from 'react-router'
import { getDisplayCurrentEndWithSinceMinutes, Queue } from '../model/queue'
import { DivMouseEvent } from '../../../common/types'
import { isPresent } from '../../../common/utils'
import { QueueTimeChip } from './QueueTimeChip'

interface Props {
  queues: Queue[]
  selectedQueueId?: string
  onQueueChange?: () => void
}

const ChipTabs = styled(Box)`
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
  gap: 8px;
  padding: 16px;
`

const Tab = styled(Paper)`
  flex-shrink: 0;
  padding: 4px 8px;
  box-shadow: 0 1px 2px 1px #00000033;
  background-color: ${props => props.theme.palette.background.paper};

  & > .main {
    display: flex;
    align-items: center;
  }

  &.selected {
    background-color: ${props => props.theme.palette.primary.light};
  }
`


export function QueueHeader({ queues, selectedQueueId, onQueueChange }: Props) {
  const navigate = useNavigate()

  function handleChangeQueue(e: DivMouseEvent, queueId: string) {
    const route = isPresent(selectedQueueId) ? `../${queueId}` : queueId

    if (isPresent(onQueueChange)) {
      onQueueChange()
    }

    navigate(route)
  }

  useEffect(() => {
    const tab = document.querySelector(`#queueTab-${selectedQueueId}`)
    tab?.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' })
  }, [selectedQueueId])


  return (
    <>
      <ChipTabs className='noScrollbar'>
        {
          queues.slice().sort((q1, q2) => q1.title.localeCompare(q2.title)).map(queue => (
            <QueueTab key={queue.id} queue={queue} selected={queue.id === selectedQueueId}
                      onClick={handleChangeQueue} />
          ))
        }
      </ChipTabs>
    </>
  )
}

interface QueueTabProps {
  queue: Queue,
  selected: boolean,
  onClick: (e: DivMouseEvent, queueId: string) => void
}

function QueueTab({ queue, selected, onClick }: QueueTabProps) {
  const sinceMinutes = getDisplayCurrentEndWithSinceMinutes(queue.currentEnd)?.sinceMinutes

  return (
    <Tab
      onClick={e => onClick(e, queue.id)}
      className={selected ? 'selected noSelect' : 'noSelect'}
      id={`queueTab-${queue.id}`}
    >
      <Box className='main'>
        <Typography display='inline'>{queue.title}</Typography>
        <span className='flexSpacer' />
        {isPresent(sinceMinutes) && <>&nbsp;<QueueTimeChip queue={queue} /></>}
      </Box>
      <Typography fontSize='smaller'>
        {
          isPresent(sinceMinutes)
            ? sinceMinutes === 0 ? 'gerade eingetragen' : `vor ${sinceMinutes} min eingetragen`
            : 'Keine Einträge'
        }
      </Typography>
    </Tab>
  )
}
