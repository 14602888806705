import * as React from 'react'
import { useEffect, useState } from 'react'
import { Global } from '@emotion/react'
import { styled } from '@mui/material/styles'
import { grey } from '@mui/material/colors'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import SwipeableDrawer from '@mui/material/SwipeableDrawer'
import { Divider } from '@mui/material'
import { Queue } from '../model/queue'
import { isAppleMobile, isBetweenIncluded } from '../../../common/utils'
import { FoodDrawerTabMenu } from './FoodDrawerTabMenu'


const drawerBleeding = isAppleMobile() ? 92 : 80
const drawerHeight = 82


interface Props {
  queues: Queue[]
  selectedQueueId?: string
}

export function FoodDrawer({ queues, selectedQueueId }: Props) {

  const [open, setOpen] = useState(false)
  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen)
  }

  useEffect(() => {

    const handler = (e: { x: number, y: number }) => {

      const clickX = e.x
      const clickY = e.y
      const width = window.innerWidth
      const height = window.innerHeight


      if (isBetweenIncluded(0, clickX, width)
        && isBetweenIncluded(height - drawerBleeding, clickY, height)) {
        setOpen(true)
      }
    }

    window.addEventListener('click', handler)

    return () => {
      window.removeEventListener('click', handler)
    }
  })


  return (

    <MenuDrawer>

      <Global
        styles={{
          '.MuiDrawer-root > .MuiPaper-root': {
            height: `calc(${drawerHeight}% - ${drawerBleeding}px)`,
            overflow: 'visible'
          }
        }}
      />

      <SwipeableDrawer
        anchor='bottom'
        open={open}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
        swipeAreaWidth={drawerBleeding}
        disableSwipeToOpen={false}
        disableBackdropTransition={false}
        ModalProps={{
          keepMounted: true,
        }}
      >

        <StyledBox
          sx={{
            position: 'absolute',
            top: -drawerBleeding,
            borderTopLeftRadius: 16,
            borderTopRightRadius: 16,
            visibility: 'visible',
            right: 0,
            left: 0,
            height: drawerBleeding
          }}
          display='flex'
          flexDirection='column'
          alignItems='center'
        >

          <Puller />

          <Typography sx={{ paddingTop: 3 }} variant='subtitle2' fontWeight='bold'>
            Speiseplan
          </Typography>

          <Typography variant='body2' color='text.secondary'>
            Mensa am Adenauerring {/*{menu?.canteen?.name ?? ''}*/}
          </Typography>

        </StyledBox>

        <Divider />

        <StyledBox sx={{ height: '100%', overflow: 'auto' }}>

          <FoodDrawerTabMenu queues={queues} selectedQueueId={selectedQueueId} toggleDrawer={setOpen} />

        </StyledBox>

      </SwipeableDrawer>

    </MenuDrawer>


  )
}


const MenuDrawer = styled(Box)(({ theme }) => ({
  height: '100%',
  backgroundColor:
    theme.palette.mode === 'light' ? grey[100] : theme.palette.background.default
}))

const StyledBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'light' ? '#fff' : grey[800]
}))

const Puller = styled(Box)(({ theme }) => ({
  width: 30,
  height: 6,
  backgroundColor: theme.palette.mode === 'light' ? grey[300] : grey[900],
  borderRadius: 3,
  position: 'absolute',
  top: 8,
  left: 'calc(50% - 15px)'
}))
