import { useRef, useState } from 'react'
import axios from 'axios'
import { serverBaseUrl } from '../../app/base'
import { UserCredentials } from '../../modules/user/model/User'
import useCallOnce from './useCallOnce'
import { getStandardCookieOptions, isNotPresent, isPresent } from '../utils'
import { useCookies } from 'react-cookie'
import jwtDecode from 'jwt-decode'

export default function useUser() {

  const baseUrl = `${serverBaseUrl}`

  const [cookies, setCookie, removeCookie] = useCookies<'authToken', { authToken: string }>()
  const [authToken, setAuthToken] = useState<string | undefined>(cookies.authToken)

  const [eaterName, setEaterName] = useState<string>('')
  const inAdminMode = useRef(false)

  useCallOnce(() => {
    handleRegistration()
  })

  async function handleRegistration() {
    if (isPresent(authToken)) {
      const isValid = await isAuthTokenValidAndSetEaterName()
      if (isValid) return

      setAuthToken(undefined)
      removeCookie('authToken')
    }

    const { data } = await registerUnknown()
    await login(data)
  }

  async function isAuthTokenValidAndSetEaterName() {
    const secureApi = getSecureApi()
    if (isNotPresent(secureApi)) return false

    try {
      await axios.get<string>(`${baseUrl}/ping`)

      // server is available
      try {
        const { data } = await secureApi.get<string>(`${baseUrl}/users/eater-name`)
        setEaterName(data)
        return true
      } catch (e) {
        return false
      }

    } catch (e) {
      // server is not available
      return true
    }


  }

  function registerUnknown() {
    return axios.post<UserCredentials>(`${baseUrl}/users/register-unknown`)
  }

  function login(credentials: UserCredentials) {
    return axios.post<string>(`${baseUrl}/login`, credentials).then(({ data }) => {
      setAuthToken(data)
      setCookie('authToken', data, getStandardCookieOptions())
    })
  }

  function logout() {
    setAuthToken(undefined)
  }

  function getSecureApi() {
    if (isNotPresent(authToken)) return undefined
    const scope = jwtDecode<{ scope: string }>(authToken).scope
    inAdminMode.current = (scope === 'ADMIN')

    return axios.create({
      headers: {
        Authorization: `Bearer ${authToken}`
      }
    })
  }

  return {
    secureApi: getSecureApi(),
    login,
    logout,
    inAdminMode: inAdminMode.current,
    eaterName
  }
}

