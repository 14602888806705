import React, { useEffect, useRef } from 'react'
import { byNumber, isNotPresent, valueOrDefault } from '../utils'
import { BezierCubic } from '../model/geometry/BezierCubic'
import { Vector2D } from '../model/geometry/Vector2D'
import { styled } from '@mui/material'
import { CurveUtils } from '../model/geometry/CurveUtils'

export type ColorMarks = { [curveT: number]: string }

interface Props {
  beziers: BezierCubic[]
  colorMarks?: ColorMarks
  isSecondary?: boolean
}

const CanvasStyled = styled('canvas')`
  top: 0;
  left: 0;
  position: absolute;
  width: 100%;
  height: 100%;
`

export function CanvasCurve({ beziers, colorMarks, isSecondary }: Props) {
  const canvasRef = useRef<HTMLCanvasElement>(null)
  const $isSecondary = valueOrDefault(isSecondary, false)

  useEffect(() => {
    renderCurve()
  })

  function renderCurve() {
    const canvas = canvasRef.current
    if (isNotPresent(canvas)) return

    canvas.width = canvas.offsetWidth
    canvas.height = canvas.offsetHeight
    const ctx = canvas.getContext('2d')
    if (isNotPresent(ctx)) return

    if (isNotPresent(colorMarks)) colorMarks = {}

    getSortedBezierColors(beziers, colorMarks).reverse().forEach(bezierColor => {
      bezierColor.curve.forEach(bezier => draw(ctx, bezierColor.color, bezier))
    })
  }

  function draw(ctx: CanvasRenderingContext2D, color: string, bezier: BezierCubic) {
    const canvas = canvasRef.current
    if (isNotPresent(canvas)) return

    const absoluteBezier = getAbsoluteFrom(bezier, canvas.width, canvas.height)

    if ($isSecondary) {
      ctx.lineWidth = 4
      ctx.setLineDash([5, 15])
    } else {
      ctx.lineWidth = 8
    }

    ctx.lineCap = 'round'
    ctx.strokeStyle = color

    ctx.beginPath()
    ctx.moveTo(absoluteBezier.start.x, absoluteBezier.start.y)
    ctx.bezierCurveTo(
      absoluteBezier.cp1.x, absoluteBezier.cp1.y,
      absoluteBezier.cp2.x, absoluteBezier.cp2.y,
      absoluteBezier.end.x, absoluteBezier.end.y
    )
    ctx.stroke()
  }

  function getAbsoluteFrom(bezier: BezierCubic, width: number, height: number) {
    width = width / 100
    height = height / 100
    return new BezierCubic(
      new Vector2D(bezier.start.x * width, bezier.start.y * height),
      new Vector2D(bezier.cp1.x * width, bezier.cp1.y * height),
      new Vector2D(bezier.cp2.x * width, bezier.cp2.y * height),
      new Vector2D(bezier.end.x * width, bezier.end.y * height),
    )
  }

  function getSortedBezierColors(beziers: BezierCubic[], colorMarks: ColorMarks) {
    const sortedCurveTs = Object.keys(colorMarks).map(parseFloat).sort(byNumber)

    return sortedCurveTs.map(curveT => {
      const color = colorMarks[curveT]
      const curve = CurveUtils.split(beziers, curveT)[0]
      return { curve, color }
    })
  }

  return (
    <>
      <CanvasStyled ref={canvasRef} />
    </>
  )
}
