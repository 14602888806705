import React, { ReactNode, useState } from 'react'
import { Button, Checkbox, FormControlLabel, FormGroup } from '@mui/material'
import { StandardContent } from '../../common/components/StandardContent'
import { StandardFooter } from '../../common/components/StandardFooter'
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material'
import { AgreeContent } from './components/AgreeContent'
import { InstallContent } from './components/InstallContent'
import { WelcomeContent } from './components/WelcomeContent'
import { StandardBar } from '../../common/components/StandardBar'
import { useNavigate, useParams } from 'react-router'
import { isPresent } from '../../common/utils'
import { StandardLayout } from '../../common/components/StandardLayout'

type StepKey = 'welcome' | 'install' | 'agree'
type StepData = { title: string, content: ReactNode, nextButton: ReactNode, controls?: ReactNode }
type Step = { [key in StepKey]: StepData }

interface Props {
  showLegal: boolean,
  showInstall: boolean,
  finish: (showLegalAgain: boolean, showInstallAgain: boolean) => void,
  removeCookies: () => void
}


export function StartPage({ showLegal, showInstall, finish, removeCookies }: Props) {
  const stepIndex = +useParams().stepIndex!
  const navigate = useNavigate()

  const [showInstallAgain, setShowInstallAgain] = useState(showInstall)
  const [showLegalAgain, setShowLegalAgain] = useState(showLegal)
  const [hasAgreeConfirmed, setHasAgreeConfirmed] = useState(!showLegal)

  const showSteps = getShowSteps(getShowStepKeys(showLegal, showInstall), getSteps())
  const maxSteps = showSteps.length

  const step = showSteps[stepIndex]


  function handleNext() {
    if (stepIndex < maxSteps - 1) {
      navigate(`/start/${stepIndex + 1}`)
    } else {
      goToApp()
    }
  }

  function handleBack() {
    setHasAgreeConfirmed(false)
    navigate(`/start/${stepIndex - 1}`)
  }

  function goToApp() {
    if (!hasAgreeConfirmed) {
      removeCookies()
      alert('Sorry, etwas ist schief gelaufen :/')
      window.location.reload()
      return
    }

    finish(showLegalAgain, showInstallAgain)
  }


  function getSteps(): Step {
    return ({
      welcome: {
        title: 'Willkommen',
        nextButton: <NextButton onClick={handleNext} disabled={false} text='Klingt nice' />,
        content: <WelcomeContent />
      },
      install: {
        title: 'Wir empfehlen',
        content: <InstallContent />,
        nextButton: <NextButton onClick={handleNext} disabled={false} text='Später' />,
        controls: (
          <FormGroup>
            <FormControlLabel control={
              <Checkbox checked={!showInstallAgain} onChange={e => setShowInstallAgain(!e.target.checked)} />
            } label='Diese Meldung nicht mehr anzeigen.' />
          </FormGroup>
        )
      },
      agree: {
        title: 'Eine Sache noch',
        content: <AgreeContent />,
        nextButton: <NextButton onClick={handleNext} disabled={!hasAgreeConfirmed} text='Zustimmen' />,
        controls:
          (
            <>
              <FormControlLabel control={
                <Checkbox checked={hasAgreeConfirmed} onChange={e => setHasAgreeConfirmed(e.target.checked)} />
              } label='Ich stimme dem oben Genannten zu.' />

              <FormControlLabel control={
                <Checkbox checked={!showLegalAgain}
                          onChange={e => setShowLegalAgain(!e.target.checked)} />
              } label='Diese Meldung nicht mehr anzeigen.' />
            </>
          )
      }
    })
  }


  return (
    <StandardLayout
      main={step.content}
      footer={
        <>
          {isPresent(step.controls) && (
            <StandardContent noVerticalPadding>
              {step.controls}
            </StandardContent>
          )}

          <StandardFooter>
            <StandardBar
              leftSlot={
                <Button size='small' onClick={handleBack} disabled={stepIndex === 0}>
                  <KeyboardArrowLeft />Zurück
                </Button>
              }
              text={`${stepIndex + 1}/${maxSteps}`}
              rightSlot={step.nextButton}
            />
          </StandardFooter>
        </>
      }
    />
  )
}


function getShowSteps(showStepKeys: StepKey[], steps: Step) {
  return (Object.keys(steps) as StepKey[])
    .filter((stepKey) => showStepKeys.includes(stepKey))
    .map(stepKey => steps[stepKey])
}

function getShowStepKeys(showLegal: boolean, showInstall: boolean) {
  const showStepKeys: StepKey[] = []
  if (showLegal) {
    showStepKeys.push('welcome')
  }
  if (showInstall) {
    showStepKeys.push('install')
  }
  if (showLegal) {
    showStepKeys.push('agree')
  }

  return showStepKeys
}

interface NextButtonProps {
  onClick: () => void,
  disabled: boolean,
  text: string
}

function NextButton({ onClick, disabled, text }: NextButtonProps) {
  return (
    <Button size='small' onClick={onClick} disabled={disabled}>
      {text}<KeyboardArrowRight />
    </Button>
  )
}
