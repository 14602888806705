import React, { useContext, useEffect, useRef, useState } from 'react'
import { getDisplayCurrentEndWithSinceMinutes, getDurationToCurvePoint, Queue } from './model/queue'
import { QueueHeader } from './components/QueueHeader'
import MapLayout from '../../common/components/map/MapLayout'
import { MapDot, MapIcon } from '../../common/components/map/map-items'
import { EmojiEventsOutlined, MoreVert, RadioButtonChecked } from '@mui/icons-material'
import Bubble from '../../common/components/Bubble'
import { Vector2D } from '../../common/model/geometry/Vector2D'
import { Box, Fab, IconButton, styled, Typography, useTheme } from '@mui/material'
import { getBoolValueFromString, getStandardCookieOptions, isNotPresent, isPresent } from '../../common/utils'
import { SelectedQueueDraw } from './components/SelectedQueueDraw'
import { OtherQueuesDraw } from './components/OtherQueuesDraw'
import { CurvePoint } from '../../common/model/geometry/GeometryData'
import { CurveUtils } from '../../common/model/geometry/CurveUtils'
import { SimpleMap } from '../../common/components/map/SimpleMap'
import { useOnChange } from '../../common/hooks/useOnChange'
import { useQueueIdUrlParam } from './hooks/useQueueIdUrlParam'
import { useInstantState } from '../../common/hooks/useInstantState'
import { useNavigate } from 'react-router'
import { FoodDrawer } from './components/FoodDrawer'
import { useMessages } from '../../common/view-systems/messages/MessageProvider'
import { useCookies } from 'react-cookie'
import { useFeedbackAnimation } from '../../common/view-systems/feedback-animation/FeedbackAnimationProvider'
import useCallOnce from '../../common/hooks/useCallOnce'
import { SecureApiContext } from '../../app/User'


interface Props {
  queues: Queue[]
  addQueueEnd: (queueId: string, curvePoint: CurvePoint) => void
}

const FilledIconButton = styled(IconButton)`
  box-shadow: 0 1px 2px 1px #00000033;
  background-color: ${props => props.theme.palette.background.paper} !important;
`


export function QueueViewerPage({ queues, addQueueEnd }: Props) {
  const selectedQueueIndex = useQueueIdUrlParam(queues)

  const selectedQueue = isPresent(selectedQueueIndex) ? queues[selectedQueueIndex] : undefined

  const [getSelectedCurvePoint, setSelectedCurvePoint] = useInstantState<CurvePoint | undefined>(undefined)
  const [mapPosition, setMapPosition] = useState<Vector2D | undefined>(selectedQueue?.location)

  const navigate = useNavigate()

  const { showMessage, closeMessage } = useMessages()
  const showFeedbackAnimation = useFeedbackAnimation()

  const [cookies, setCookie] = useCookies<'dontShowMessage', { dontShowMessage: boolean }>()

  const tabCounterRef = useRef(0)
  const messageIdRef = useRef<string>()

  const { eaterName } = useContext(SecureApiContext)


  useEffect(() => {
    if (isNotPresent(selectedQueue)) return
    if (isNotPresent(selectedQueue.currentEnd)) {
      const newMapPosition = CurveUtils.getVectorAt(
        CurveUtils.getCurvePointFrom(
          CurveUtils.getMaxCurveT(
            selectedQueue.path
          )
        ),
        selectedQueue.path
      ).add(selectedQueue.location).scale(0.5)

      setMapPosition(newMapPosition)
    }
  }, [selectedQueueIndex])

  useOnChange(() => {
    setSelectedCurvePoint(undefined)
  }, [selectedQueueIndex])

  const selectedCurvePoint = getSelectedCurvePoint()

  function getAllQueuesExceptSelected() {
    return queues.filter((_, index) => selectedQueueIndex !== index)
  }

  function handleCounter() {
    tabCounterRef.current++

    if (tabCounterRef.current >= 12) {
      tabCounterRef.current = 0
      showFeedbackAnimation(<Typography sx={{ fontSize: '100vw' }} className='noSelect'>🐣</Typography>)
    }
  }

  useCallOnce(() => {
    setInterval(() => {
      tabCounterRef.current = 0
    }, 6000)
  })

  function handleSetSelectedVectorToClosest(vector: Vector2D) {
    handleCounter()
    const curvePoints = queues.map(
      queue => CurveUtils.getClosestTOfClosestBezier(vector, queue.path))

    let closestCurveIndex = -1
    let closestDistance = Infinity

    for (let i = 0; i < curvePoints.length; i++) {
      const distance = CurveUtils.getVectorAt(curvePoints[i], queues[i].path).getDistance(vector)
      if (distance < closestDistance) {
        closestDistance = distance
        closestCurveIndex = i
      }
    }

    if (closestDistance > 10) {
      setSelectedCurvePoint(undefined)
      return
    }

    const clickedQueue = queues[closestCurveIndex]

    if (clickedQueue.id === selectedQueue?.id) {
      setSelectedCurvePoint(curvePoints[closestCurveIndex])
      return
    }

    if (isPresent(selectedQueueIndex)) {
      navigate(`../${clickedQueue.id}`)
    } else {
      navigate(clickedQueue.id)
    }
  }

  function handleUpdateQueueEnd() {
    if (isNotPresent(selectedQueue) || isNotPresent(selectedCurvePoint)) return
    addQueueEnd(selectedQueue.id, selectedCurvePoint)
    setSelectedCurvePoint(undefined)

    const dontShowMessageCookie = cookies['dontShowMessage']
    if (isPresent(dontShowMessageCookie) && getBoolValueFromString(dontShowMessageCookie)) {
      showFeedbackAnimation()
    } else {
      messageIdRef.current = showMessage({
        firstLine: 'Danke für deinen Beitrag!',
        secondLine: 'Dein Eintrag trägt zur Berechnung der Schlangenlänge bei. Dies ist womöglich nicht direkt erkennbar.',
        severity: 'success',
        onClose: () => {
          setCookie('dontShowMessage', true, getStandardCookieOptions())
          closeMessage(messageIdRef.current)
        }
      }, messageIdRef.current)
    }


  }

  const moreLongDownRef = useRef(false)
  const moreTimeoutRef = useRef<ReturnType<typeof setTimeout>>()

  function handleMoreDown() {
    moreTimeoutRef.current = setTimeout(() => {
      moreLongDownRef.current = true
    }, 10_000)
  }

  function handleMoreUp() {
    clearTimeout(moreTimeoutRef.current)
    if (moreLongDownRef.current) {
      moreLongDownRef.current = false
      showMessage({ firstLine: eaterName })
    } else {
      navigate('/informationen')
    }
  }

  return (
    <MapLayout
      header={
        <QueueHeader queues={queues} selectedQueueId={selectedQueue?.id} />
      }
      main={
        <>
          <FilledIconButton
            sx={{ position: 'absolute', zIndex: 1000, top: '5rem', right: '16px', width: '32px', height: '32px' }}
            onTouchStart={handleMoreDown}
            onTouchEnd={handleMoreUp}
            onMouseUp={handleMoreUp}>
            <MoreVert />
          </FilledIconButton>

          <FilledIconButton
            sx={{ position: 'absolute', zIndex: 1000, top: '5rem', left: '16px', width: '32px', height: '32px' }}
            size='small'
            onClick={() => navigate('/leaderboard')}>
            <EmojiEventsOutlined />
          </FilledIconButton>


          {selectedCurvePoint && (
            <Box sx={{
              display: 'flex',
              position: 'absolute',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
              height: '0',
              left: '0',
              bottom: '128px'
            }}>
              <Fab variant='extended' size='large' color='primary'
                   onClick={handleUpdateQueueEnd}>
                Ende der Schlange
              </Fab>
            </Box>

          )}

          <SimpleMap onClick={handleSetSelectedVectorToClosest}
                     position={mapPosition}>

            <OtherQueuesDraw queues={getAllQueuesExceptSelected()} />

            {
              isPresent(selectedQueue) && (
                <>
                  <SelectedQueueDraw location={selectedQueue.location} beziers={selectedQueue.path}
                                     currentEnd={getDisplayCurrentEndWithSinceMinutes(selectedQueue.currentEnd)?.currentEnd} />

                  <CurrentEnd queue={selectedQueue} />
                </>
              )
            }

            {selectedCurvePoint &&
              <SelectedPoint selectedCurvePoint={selectedCurvePoint} selectedQueue={selectedQueue} />}
          </SimpleMap>
        </>
      }
      footer={
        <FoodDrawer queues={queues} selectedQueueId={selectedQueue?.id} />
      }
    />
  )
}

function CurrentEnd({ queue }: { queue: Queue }) {
  const color = useTheme().palette.error.dark
  if (isNotPresent(queue.currentEnd)) return <></>

  const currentEndWithSinceMinutes = getDisplayCurrentEndWithSinceMinutes(queue.currentEnd)

  if (isNotPresent(currentEndWithSinceMinutes)) return <></>

  const currentEndVector = CurveUtils.getVectorAt(queue.currentEnd.curvePoint, queue.path)

  return (
    <>
      <MapDot vector={currentEndVector} size='large' backgroundColor={color} />
    </>
  )
}

function SelectedPoint({
                         selectedCurvePoint,
                         selectedQueue
                       }: { selectedCurvePoint: CurvePoint, selectedQueue?: Queue }) {

  if (isNotPresent(selectedQueue)) return <></>
  const vector = CurveUtils.getVectorAt(selectedCurvePoint, selectedQueue.path)

  const duration = getDurationToCurvePoint(selectedQueue, selectedCurvePoint)

  return (
    <>
      <MapIcon vector={vector}>
        <RadioButtonChecked color='error' />
      </MapIcon>
      {
        isPresent(duration) && (
          <Bubble x={vector.x} y={vector.y}>
            {duration} min
          </Bubble>
        )
      }
    </>
  )
}
