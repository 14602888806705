import React from 'react'
import { keyframes, Paper, PaperProps, styled, Typography } from '@mui/material'

const jump = keyframes`
  0% {
    transform: translateY(-8px);
  }
  25% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-8px);
  }
`


const BubbleWrapper = styled(Paper)`
  position: absolute;
  border-radius: 8px;
  width: 5rem;
  height: 1.5rem;
  //animation: ${jump} 700ms linear infinite;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: none;
  z-index: 900;
  opacity: 0.8;


  /*&:after {
    content: '';
    position: absolute;
    display: block;
    width: 0;
    border-style: solid;
    border-color: ${props => props.theme.palette.background.paper} transparent;
    border-width: 8px 8px 0;
    bottom: -6px;
    left: 50%;
    margin-left: -8px;
    z-index: 901;
  }*/
`


interface Props {
  y: number
  x: number
}

const Bubble = (props: PaperProps & Props) => {

  return (
    <BubbleWrapper {...props} sx={{
      top: `calc(${props.y}% - 3rem)`,
      left: `calc(${props.x}% - 2.5rem)`
    }}>
      <Typography variant='body2'>{props.children}</Typography>
    </BubbleWrapper>
  )
}

export default Bubble
