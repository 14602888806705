export interface MenuLine {
  id: string
  name: string
  meals: Meal[]
}

export interface Meal {
  name: string
  price: string
  classifiers: Classifier[]
}

export enum Classifier {
  VEGGIE = 'VEG',
  VEGAN = 'VG',
  BEEF = 'R',
  PORK = 'S',
  FISH = 'MSC'



  /*
  * VEGGIE = 'VEGGIE',
  VEGAN = 'VEGAN',
  COW = 'COW',
  PORK = 'PORK',
  FISH = 'FISH'*/

}

export interface DayMenu {
  canteen: Canteen
  date: Date
  lines: MenuLine[]
}

export interface Canteen {
  id: string
  name: string
}
