import { useRef } from 'react'
import { Vector2D } from '../../../common/model/geometry/Vector2D'
import { isNotPresent } from '../../../common/utils'
import { CurveVectorReference } from '../util/actions'
import { BezierCubic } from '../../../common/model/geometry/BezierCubic'


export default function useBezierVectorAlign() {
  const templateRef = useRef<CurveVectorReference>()

  function getAlignedBy(beziers: BezierCubic[], reference: CurveVectorReference,
                        newVectorCalculator: (templateVector: Vector2D, oldVector: Vector2D) => Vector2D) {
    const template = templateRef.current

    if (isNotPresent(template)) {
      templateRef.current = reference
      return [...beziers]
    }
    if (template.equals(reference)) {
      finishAlignment()
      return [...beziers]
    }
    const oldVector = reference.getVector(beziers)
    const templateVector = template.getVector(beziers)
    const newVector = newVectorCalculator(templateVector, oldVector)
    const newBeziers = reference.replaceVector(beziers, newVector)

    finishAlignment()
    return newBeziers

  }

  function getAlignedByX(beziers: BezierCubic[], reference: CurveVectorReference) {
    return getAlignedBy(beziers, reference,
      (templateVector, oldVector) => new Vector2D(templateVector.x, oldVector.y))
  }

  function getAlignedByY(beziers: BezierCubic[], reference: CurveVectorReference) {
    return getAlignedBy(beziers, reference,
      (templateVector, oldVector) => new Vector2D(oldVector.x, templateVector.y))
  }

  function finishAlignment() {
    templateRef.current = undefined
  }

  return {
    alignTemplate: templateRef.current,
    getAlignedByX,
    getAlignedByY,
    finishAlignment
  }
}
