export const randomId = () =>
  's' + Math.random().toString().replace('.', '')

export function isNotPresent(value: any): value is null | undefined {
  return value === undefined || value === null
}

export function isPresent<T>(value: T | undefined | null): value is T {
  return !isNotPresent(value)
}

export function valueOrDefault<T>(value: T | undefined, defaultValue: T): T {
  return isPresent(value) ? value : defaultValue
}

export function valueOrThrow<T>(value: T | undefined) {
  if (isNotPresent(value)) throw new Error(`${value} must be present`)
  return value
}

export const postDecode = (object: any) => {
  const mutObject = { ...object }
  for (const key in mutObject) {
    if (typeof mutObject[key] === object) {
      mutObject[key] = postDecode(mutObject[key])
    }
    if (key.endsWith('Date')) {
      mutObject[key] = new Date(key)
    }
  }
  return mutObject
}

export function getNavigationLocation() {
  return window.location.href.substring(window.location.origin.length)
}

export function byNumber(a: number, b: number) {
  return a - b
}

export function stringToInt(s: string) {
  return +s
}

export function isBetweenIncluded(lower: number, value: number, upper: number) {
  return isBetweenExcluded(lower - 1, value, upper + 1)
}

export function isBetweenExcluded(lower: number, value: number, upper: number) {
  return lower < value && value < upper
}

export function isAppleMobile() {
  return /iPad|iPhone|iPod/.test(navigator.userAgent)
}

export function getBoolValueFromString(value: boolean | string) {
  if (value === true || value === 'true') return true
  if (value === false || value === 'false') return false
  return undefined
}

export function getStandardCookieOptions() {
  const inOneYear = new Date(Date.now() + 1000 * 3600 * 24 * 365)
  return { expires: inOneYear, path: '/' }
}
