import React, { createContext, useContext, useState } from 'react'
import { Box, styled } from '@mui/material'
import { MessageView } from './MessageView'
import { ChildrenProps } from '../../types'
import { isPresent, randomId, valueOrThrow } from '../../utils'

export interface Message {
  firstLine?: string,
  secondLine?: string,
  severity?: 'success' | 'error' | 'warning' | 'info',
  onClose?: () => void,
  id?: string
}

type MessageActions = {
  showMessage: (message: Message, messageId?: string) => string,
  closeMessage: (messageId?: string) => void
}

const MessageContext = createContext<MessageActions>(
  {
    showMessage: () => '',
    closeMessage: () => undefined
  }
)


export function MessageProvider({ children }: ChildrenProps) {

  const [messages, setMessages] = useState<Message[]>([])

  function showMessage(message: Message, messageId?: string) {
    if (isPresent(messageId) && messages.filter(m => m.id === messageId).length > 0) {
      return messageId
    }

    const id = randomId()
    const $message = { ...message, id }
    setMessages(prevState => [...prevState, $message])
    return id
  }

  function closeMessage(id?: string) {
    const $id = valueOrThrow(id)
    setMessages(prevState => prevState.filter(message => message.id !== $id))
  }

  function handleOnClose(message: Message) {
    const id = valueOrThrow(message.id)

    if (isPresent(message.onClose)) {
      message.onClose()
    } else {
      closeMessage(id)
    }
  }

  return (
    <MessageContext.Provider value={{ showMessage, closeMessage }}>
      {children}
      <MessagesBox>
        {messages.map(message => (
          <MessageView key={message.id} {...message} onClose={() => handleOnClose(message)} />
        ))}
      </MessagesBox>
    </MessageContext.Provider>
  )
}

export function useMessages() {
  return useContext(MessageContext)
}

const MessagesBox = styled(Box)`
  position: fixed;
  bottom: 16px;
  left: 0;
  box-sizing: border-box;
  z-index: 1500;
  padding: 0 16px;
  display: flex;
  gap: 8px;
  flex-direction: column;

  @media only screen and (max-width: 600px) {
    width: 100vw;
    max-width: 100%;
  }

  & > div {
    box-sizing: border-box;
    animation: slideIn;
    animation-duration: 500ms;
  }

  @keyframes slideIn {
    0% {
      transform: translateX(-32px);
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      transform: translateX(0);
    }
  }
`
