import React from 'react'
import { CanvasCurve, ColorMarks } from '../../../common/components/CanvasCurve'
import { MapIcon } from '../../../common/components/map/map-items'
import { Place } from '@mui/icons-material'
import { BezierCubic } from '../../../common/model/geometry/BezierCubic'
import { Vector2D } from '../../../common/model/geometry/Vector2D'

interface Props {
  beziers: BezierCubic[],
  colorMarks: ColorMarks,
  isSecondary?: boolean
  location?: Vector2D,
  locationColor?: string
}

export function QueueDraw({ beziers, colorMarks, location, locationColor, isSecondary }: Props) {
  return (
    <>
      <CanvasCurve beziers={beziers} colorMarks={colorMarks} isSecondary={isSecondary} />

      {location && (
        <MapIcon vector={location} align='end'>
          <Place sx={{ fontSize: '36px', color: locationColor }} />
        </MapIcon>
      )}
    </>
  )
}
