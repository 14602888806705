import React from 'react'
import { SimpleMap } from '../../../common/components/map/SimpleMap'
import { MapDot } from '../../../common/components/map/map-items'
import { BezierCubic } from '../../../common/model/geometry/BezierCubic'
import { Vector2D } from '../../../common/model/geometry/Vector2D'
import { SelectedQueueDraw } from './SelectedQueueDraw'
import { CurveVectorReference, MapDotAction, ShowDotOption, VectorAction } from '../util/actions'
import { isNotPresent } from '../../../common/utils'

interface BezierMapDotProps {
  beziers: BezierCubic[]
  reference: CurveVectorReference
  backgroundColor: string
}

interface Props {
  beziers: BezierCubic[]
  location?: Vector2D
  showDots?: ShowDotOption
  onPressedMove?: VectorAction
  onMapClick?: VectorAction
  onDotDown?: MapDotAction
  onDotUp?: MapDotAction
  markedVector?: CurveVectorReference
}


export default function BezierMap({
                                    location,
                                    beziers,
                                    showDots,
                                    onPressedMove,
                                    onMapClick,
                                    onDotDown,
                                    onDotUp,
                                    markedVector
                                  }: Props) {
  if (isNotPresent(showDots)) showDots = 'none'


  const handleOnDotDown: MapDotAction = (mouseVector, reference) => {
    if (isNotPresent(onDotDown)) return
    onDotDown(mouseVector, reference)
  }

  const handleOnDotUp: MapDotAction = (mouseVector, reference) => {
    if (isNotPresent(onDotUp)) return
    onDotUp(mouseVector, reference)
  }

  function showAllDots() {
    return showDots === 'all'
  }

  function showStartDot() {
    return showAllDots() || showDots === 'start'
  }

  function getBorderColor(vector: CurveVectorReference) {
    if (isNotPresent(markedVector)) return undefined
    return vector.equals(markedVector) ? 'red' : undefined
  }

  function BezierMapDot({ beziers, reference, backgroundColor }: BezierMapDotProps) {
    return (
      <MapDot vector={reference.getVector(beziers)} size='large'
              backgroundColor={backgroundColor} borderColor={getBorderColor(reference)}
              onMouseDown={mouseVector => handleOnDotDown(mouseVector, reference)}
              onMouseUp={mouseVector => handleOnDotUp(mouseVector, reference)} />
    )
  }

  return (
    <>
      <SimpleMap
        onPressedMove={onPressedMove}
        onClick={onMapClick}
        position={location}
      >
        <SelectedQueueDraw beziers={beziers} location={location} />

        {
          beziers.map((bezier, index) => (
            <React.Fragment key={index}>

              {showStartDot() && <BezierMapDot beziers={beziers} backgroundColor='darksalmon'
                                               reference={new CurveVectorReference(index, 'start')} />}

              {
                showAllDots() && (
                  <>
                    <BezierMapDot beziers={beziers} backgroundColor='darkseagreen'
                                  reference={new CurveVectorReference(index, 'end')} />

                    <BezierMapDot beziers={beziers} backgroundColor='darkorchid'
                                  reference={new CurveVectorReference(index, 'cp2')} />

                    <BezierMapDot beziers={beziers} backgroundColor='darkslateblue'
                                  reference={new CurveVectorReference(index, 'cp1')} />
                  </>
                )
              }


              {/*{range(1, 30).map(n => (
                <Dot key={n} vector={beziers[index].at(n / 30)} color='coral' />
              ))}*/}


            </React.Fragment>
          ))
        }

      </SimpleMap>

    </>
  )
}


