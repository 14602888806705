import React, { useEffect, useState } from 'react'
import { Box, styled, Typography } from '@mui/material'
import { serverBaseUrl } from '../../../app/base'
import { StandardContent } from '../../../common/components/StandardContent'
import axios from 'axios'

interface Overview {
  days: number
  entries: number
  users: number
}

export function OverviewContent() {

  const [overview, setOverview] = useState<Overview>()

  useEffect(() => {
    axios.get<Overview>(serverBaseUrl + '/api/v1/overview')
      .then(({ data }) => setOverview(data))
  }, [])

  return (
    <StandardContent>
      <Content className='noSelect'>

        <div>
          <Box textAlign='center'>
            <Typography variant='subtitle1'>Seit Release:</Typography>
          </Box>
        </div>

        <div>
          <div>
            <Typography variant='h5'>{overview?.days}</Typography>
            <Typography>Tage</Typography>
          </div>

          <div>
            <Typography variant='h4'>{overview?.entries}</Typography>
            <Typography>Einträge</Typography>
          </div>

          <div>
            <Typography variant='h5'>{overview?.users}</Typography>
            <Typography>Benutzer</Typography>
          </div>
        </div>
      </Content>
    </StandardContent>
  )
}

const Content = styled(Box)`
  text-align: center;

  & > div {
    display: flex;
    align-items: center;

    & > div {
      flex: 1 0 0;
    }
  }
`
