import { useContext, useEffect, useState } from 'react'
import { serverBaseUrl } from '../../app/base'
import { SecureApiContext } from '../../app/User'
import { DayMenu } from '../../modules/queue/model/menu'

export default function useMenu(): DayMenu[] | undefined {

  const { secureApi } = useContext(SecureApiContext)

  const [menu, setMenu] = useState<DayMenu[]>([])

  const nextSevenDays: string[] = getNextSevenDays();

  useEffect(() => {
    loadMenu()
  }, [])

  async function loadMenu() {

    const sevenDaysData: DayMenu[] = [];

    for (const day of nextSevenDays) {
      const { data } = await secureApi<DayMenu>(`${serverBaseUrl}/menu/${new Date(day).toISOString()}`)
      sevenDaysData.push(data)
    }

    setMenu(sevenDaysData)
  }

  return menu
}

function getNextSevenDays(): string[] {
  const days: string[] = [];
  const today: Date = new Date();

  for (let i = 0; i < 7; i++) {
    const date: Date = new Date();
    date.setDate(today.getDate() + i);
    const year: number = date.getFullYear();
    const month: number = date.getMonth() + 1;
    const day: number = date.getDate();
    const formattedDate: string = `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;
    days.push(formattedDate);
  }

  return days;
}

