import { useState } from 'react'
import { ActionValue, MapDotAction, ShowDotOption, VectorAction } from '../util/actions'
import { isNotPresent } from '../../../common/utils'
import { Queue } from '../model/queue'

interface ActionCollection {
  value?: ActionValue
  onPressedMove?: VectorAction
  onMapClick?: VectorAction
  onDotDown?: MapDotAction
  onDotUp?: MapDotAction
  showDots?: ShowDotOption
}

type Actions = { [actionValue in ActionValue]: ActionCollection }

export default function useEditorActions(
  queue: Queue,
  editCurve: VectorAction,
  setEditConfig: MapDotAction,
  clearEditConfig: MapDotAction,
  removeBezier: MapDotAction,
  changeLocation: VectorAction,
  getAlignedByX: MapDotAction,
  getAlignedByY: MapDotAction,
  finishAlignment: () => void,
) {

  const [actionCollection, setActionCollection] = useState<ActionCollection>()

  const actions: Actions = {
    [ActionValue.EDIT]: {
      value: ActionValue.EDIT,
      onPressedMove: editCurve,
      onDotDown: setEditConfig,
      onDotUp: clearEditConfig,
      showDots: 'all'
    },
    [ActionValue.REMOVE]: {
      value: ActionValue.REMOVE,
      onDotUp: removeBezier,
      showDots: 'start'
    },
    [ActionValue.ALIGN_X]: {
      value: ActionValue.ALIGN_X,
      onDotUp: getAlignedByX,
      showDots: 'all'
    },
    [ActionValue.ALIGN_Y]: {
      value: ActionValue.ALIGN_Y,
      onDotUp: getAlignedByY,
      showDots: 'all'
    },
    [ActionValue.LOCATION]: {
      value: ActionValue.LOCATION,
      onMapClick: changeLocation,
    },
  }

  function setActions(actionValue?: ActionValue) {
    finishAlignment()
    if (isNotPresent(actionValue)) {
      setActionCollection({})
      finishAlignment()
    } else {
      setActionCollection(actions[actionValue])
    }
  }

  return {
    actionValue: actionCollection?.value,
    setActions,
    onPressedMove: actionCollection?.onPressedMove,
    onMapClick: actionCollection?.onMapClick,
    onDotDown: actionCollection?.onDotDown,
    onDotUp: actionCollection?.onDotUp,
    showDots: actionCollection?.showDots
  }
}
