import React from 'react'
import { Button, IconButton, List, ListItem, Paper } from '@mui/material'
import { Queue } from './model/queue'
import { Delete } from '@mui/icons-material'
import { ListItemLink } from '../../common/base'

interface Props {
  queues: Queue[]
  addQueue: () => void
  deleteQueue: (id: string) => void
}

const QueueEditListPage = ({ queues, addQueue, deleteQueue }: Props) => {

  return (
    <Paper>
      <Button onClick={addQueue} variant='contained'>Add Queue</Button>
      <List component='nav' aria-label='secondary mailbox folder'>
        {queues.map(queue => (
          <ListItem key={queue.id}
                    secondaryAction={
                      <IconButton edge='end' aria-label='delete' onClick={() => deleteQueue(queue.id)}>
                        <Delete />
                      </IconButton>
                    }>
            <ListItemLink href={queue.id}>
              {queue.title}
            </ListItemLink>
          </ListItem>

        ))}
      </List>
    </Paper>
  )
}

export default QueueEditListPage
