import React from 'react'
import { StandardTemplate } from '../../common/components/StandardTemplate'

interface Props {

}

export function QrCodePage({}: Props) {
  return (
    <StandardTemplate title='QR Code'>
      <img src='/res/images/qr-code-logo.png' alt='' style={{ width: '100%' }} />
    </StandardTemplate>
  )
}
