import React from 'react'
import { Vector2D } from '../../../common/model/geometry/Vector2D'
import { BezierCubic } from '../../../common/model/geometry/BezierCubic'
import { TimedCurvePoint } from '../../../common/model/geometry/GeometryData'
import { CurveUtils } from '../../../common/model/geometry/CurveUtils'
import { isPresent } from '../../../common/utils'
import { QueueDraw } from './QueueDraw'
import { useTheme } from '@mui/material'

interface Props {
  beziers: BezierCubic[]
  location?: Vector2D
  currentEnd?: TimedCurvePoint
}


export function SelectedQueueDraw({ beziers, location, currentEnd }: Props) {
  const theme = useTheme()
  const mainColor = theme.palette.primary.main
  const queuedCurveColor = theme.palette.error.dark

  const colorMarks = { [CurveUtils.getMaxCurveT(beziers)]: mainColor }
  if (isPresent(currentEnd)) {
    colorMarks[CurveUtils.getCurveTFrom(currentEnd.curvePoint)] = queuedCurveColor
  }

  return (
    <QueueDraw beziers={beziers} colorMarks={colorMarks}
               location={location} locationColor={mainColor} />
  )
}
