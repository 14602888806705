import React from 'react'
import { StandardTemplate } from '../../common/components/StandardTemplate'

interface Props {

}

export function LegalNoticePage({}: Props) {
  return (
    <StandardTemplate title='Impressum'>
      Julian Winter <br />
      <br />
      Haid-Und-Neu-Staße 6<br />
      76131 Karlsruhe<br />
      Deutschland<br />
      <br />
      E-Mail: julian.winter@student.kit.edu<br />
      Telefon: +49 176 29380462<br />
    </StandardTemplate>
  )
}
