import React, { useState } from 'react'
import { createTheme, ThemeOptions, ThemeProvider } from '@mui/material'
import { ChildrenProps } from '../common/types'

const lightThemeOptions: ThemeOptions = {
  palette: {
    mode: 'light',
    primary: {
      main: 'hsl(205,100%,50%)',
      light: 'hsl(205,100%,97%)',
      dark: 'hsl(205,100%,40%)',
    },
    secondary: {
      main: 'hsl(205,100%,90%)',
      light: 'hsl(205,100%,95%)',
      dark: 'hsl(205,100%,85%)',
    },
    info: {
      main: 'hsl(205,100%,50%)',
      light: 'hsl(205,100%,70%)',
      dark: 'hsl(205,100%,40%)',
    },
    error: {
      main: 'hsl(0,80%,40%)',
      light: 'hsl(0,80%,85%)',
      dark: 'hsl(0,80%,50%)',
    },
    background: {
      default: 'hsl(0,0%,96%)',
      paper: 'hsl(0,0%,100%)'
    },
  },

  shape: {
    borderRadius: 16
  }
}


const darkThemeOptions: ThemeOptions = {
  palette: {
    mode: 'dark',
    primary: {
      main: 'hsl(205,100%,50%)',
      light: 'hsl(205,100%,70%)',
      dark: 'hsl(205,100%,40%)',
    },
    secondary: {
      main: 'hsl(205,100%,30%)',
      light: 'hsl(205,100%,35%)',
      dark: 'hsl(205,100%,25%)',
    },
    info: {
      main: 'hsl(205,100%,50%)',
      light: 'hsl(205,100%,70%)',
      dark: 'hsl(205,100%,40%)',
    },
    background: {
      default: 'hsl(0,0%,7%)',
      paper: 'hsl(0,0%,20%)'
    },
  },
}

const Theme = ({ children }: ChildrenProps) => {
  const darkThemeMatcher = window.matchMedia('(prefers-color-scheme: dark)')
  // const getMatchingTheme = (matches: boolean) => matches ? 'dark' : 'light'
  const getMatchingTheme = (matches: boolean) => 'light' as 'light'

  const allThemeOptions = {
    light: lightThemeOptions,
    dark: darkThemeOptions,
  }

  const [themeOptionsKey, setThemeOptionsKey] = useState<'light' | 'dark'>(getMatchingTheme(darkThemeMatcher.matches))

  const theme = createTheme(allThemeOptions[themeOptionsKey])

  const bgColor = theme.palette.background.default
  document.querySelector('meta[name=theme-color]')?.setAttribute('content', bgColor)

  const body = document.querySelector('body')
  if (body) {
    body.style.background = bgColor
  }


  darkThemeMatcher.addEventListener('change', e => {
    setThemeOptionsKey(getMatchingTheme(e.matches))
  })

  const appHeight = () => {
    const doc = document.documentElement
    doc.style.setProperty('--app-height', `${window.innerHeight}px`)

  }
  window.addEventListener('resize', appHeight)
  window.addEventListener('orientationchange', appHeight)
  appHeight()


  return (
    <ThemeProvider theme={theme}>
      {children}
    </ThemeProvider>

  )
}

export default Theme
