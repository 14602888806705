import { BezierCubic } from '../../../common/model/geometry/BezierCubic'
import { Vector2D } from '../../../common/model/geometry/Vector2D'
import { CurvePoint, TimedCurvePoint } from '../../../common/model/geometry/GeometryData'
import { isNotPresent } from '../../../common/utils'
import { CurveUtils } from '../../../common/model/geometry/CurveUtils'

export interface BaseQueue {
  title: string
  path: BezierCubic[]
  location: Vector2D
  velocity: number
  velocityOffset: number
  currentEnd?: TimedCurvePoint
  apiLineId?: string
}

export interface Queue extends BaseQueue {
  id: string
}


export function queueToApiQueue(queue: Queue) {
  /*const time = queue.currentEnd?.time.toISOString()
  return {...queue, currentEnd: { queue.currentEnd }}*/
}

export function getDisplayCurrentEndWithSinceMinutes(currentEnd?: TimedCurvePoint) {
  function getSinceMinutes(date: Date) {
    // add 100ms to prevent negative results because of minimal differences
    const sinceMs = new Date().getTime() - date.getTime() + 100
    return Math.round(new Date(sinceMs).getTime() / (1000 * 60))
  }

  if (isNotPresent(currentEnd)) return undefined

  const sinceMinutes = getSinceMinutes(currentEnd.time)
  return sinceMinutes > 15 ? undefined : { currentEnd, sinceMinutes }
}

export function getDuration(queue: Queue) {
  if (isNotPresent(queue.currentEnd)) return undefined
  const length = CurveUtils.getLengthToCurvePoint(queue.currentEnd.curvePoint, queue.path)
  return Math.round((length / queue.velocity) + queue.velocityOffset)
}

export function getDurationToCurvePoint(selectedQueue: Queue, selectedCurvePoint: CurvePoint) {
  if (isNotPresent(selectedQueue)) return undefined
  const length = CurveUtils.getLengthToCurvePoint(selectedCurvePoint, selectedQueue.path)
  return Math.round((length / selectedQueue.velocity) + selectedQueue.velocityOffset)

}
