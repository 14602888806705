import { Queue } from '../model/queue'
import useEditCurve from './useEditCurve'
import useBezierVectorAlign from './useBezierVectorAlign'
import { BezierCubic } from '../../../common/model/geometry/BezierCubic'
import { Vector2D } from '../../../common/model/geometry/Vector2D'
import { CurveVectorReference, MapDotAction } from '../util/actions'
import { isNotPresent } from '../../../common/utils'
import useSelectedQueue from './useSelectedQueue'

export function useEditQueue(queues: Queue[], selectedQueueIndex: number) {
  const { selectedQueue, setSelectedQueue } = useSelectedQueue(queues, selectedQueueIndex)

  const { getEditedCurve, setEditConfig, clearEditConfig } = useEditCurve()

  const { alignTemplate, getAlignedByX, getAlignedByY, finishAlignment } = useBezierVectorAlign()

  function createFirstBezier() {
    return new BezierCubic(
      new Vector2D(40, 40),
      new Vector2D(50, 60),
      new Vector2D(70, 60),
      new Vector2D(80, 30),
    )
  }

  function createNextBezier(beziers: BezierCubic[]) {
    const prev = beziers[beziers.length - 1]
    const d = prev.end.subtract(prev.cp2).normalize().scale(2)

    const cp1 = prev.end.add(d)
    const cp2 = cp1.add(d.rotate90Left())
    const end = cp2.add(d)

    return new BezierCubic(
      Vector2D.fromVector(prev.end),
      Vector2D.fromVector(cp1),
      Vector2D.fromVector(cp2),
      Vector2D.fromVector(end),
    )
  }


  function addBezier() {
    const beziers = selectedQueue.path
    const newBezier = beziers.length === 0 ? createFirstBezier() : createNextBezier(beziers)

    setSelectedQueue(prevState => (
      { ...prevState, path: [...prevState.path, newBezier] }
    ))
  }

  function removeBezier(_: Vector2D, { bezierIndex }: CurveVectorReference) {
    setSelectedQueue(prevState => (
      {
        ...prevState,
        path: prevState.path.filter((_, index) => index !== bezierIndex)
      }
    ))
  }

  function editCurve(vector: Vector2D) {
    if (getEditedCurve) {
      setSelectedQueue(prevState => {
        const editedBeziers = getEditedCurve(prevState.path, vector)
        return isNotPresent(editedBeziers)
          ? prevState
          : { ...prevState, path: editedBeziers }
      })
    }
  }

  function changeTitle(title: string) {
    setSelectedQueue(prevState => ({
      ...prevState, title
    }))
  }

  function changeApiLineId(apiLineId: string) {
    setSelectedQueue(prevState => ({
      ...prevState, apiLineId
    }))
  }

  function changeVelocity(velocity: number) {
    setSelectedQueue(prevState => ({
      ...prevState, velocity
    }))
  }

  function changeVelocityOffset(velocityOffset: number) {
    setSelectedQueue(prevState => ({
      ...prevState, velocityOffset
    }))
  }

  function changeLocation(location: Vector2D) {
    setSelectedQueue(prevState => ({
      ...prevState, location
    }))
  }

  const handleSetEditConfig: MapDotAction = (_, reference) =>
    setEditConfig(reference)

  function handleAlignment(reference: CurveVectorReference,
                           aligner: (beziers: BezierCubic[], reference: CurveVectorReference) => BezierCubic[]) {
    setSelectedQueue(prevState => {
      const newBeziers = aligner(prevState.path, reference)
      return { ...prevState, path: newBeziers }
    })
  }

  const handleGetAlignedByX: MapDotAction = (_, reference) => {
    handleAlignment(reference, getAlignedByX)
  }

  const handleGetAlignedByY: MapDotAction = (_, reference) => {
    handleAlignment(reference, getAlignedByY)
  }

  return {
    selectedQueue,
    addBezier,
    removeBezier,
    editCurve,
    setEditConfig: handleSetEditConfig,
    clearEditConfig,
    changeLocation,
    changeTitle,
    changeApiLineId,
    changeVelocity,
    changeVelocityOffset,
    alignTemplate,
    getAlignedByX: handleGetAlignedByX,
    getAlignedByY: handleGetAlignedByY,
    finishAlignment,
  }
}
