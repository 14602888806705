import { Link as ReactRouterLink } from 'react-router-dom'
import { Link as MuiLink, LinkProps as MuiLinkProps, ListItemButton, ListItemButtonProps } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router'

export const StdLink: React.FC<MuiLinkProps> = props => {
  return (
    <MuiLink to={props.href ?? ''} {...props} component={ReactRouterLink}></MuiLink>
  )
}

export function ListItemLink(props: ListItemButtonProps & { href: string }) {
  const navigate = useNavigate()

  return (
    <ListItemButton {...props} onClick={() => navigate(props.href)}>
      {props.children}
    </ListItemButton>
  )
}
