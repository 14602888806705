import React from 'react'
import { ChildrenProps } from '../types'
import { Paper } from '@mui/material'

interface Props extends ChildrenProps {
  noBottomMargin?: boolean,
  noHorizontalMargin?: boolean,
  noVerticalPadding?: boolean,
  noHorizontalPadding?: boolean
}

export function StandardContent({
                                 noBottomMargin,
                                 noHorizontalMargin,
                                 noVerticalPadding,
                                 noHorizontalPadding,
                                 children
                               }: Props) {

  const bottomMargin = noBottomMargin ? '0' : '16px'
  const horizontalMargin = noHorizontalMargin ? '0' : '16px'
  const verticalPadding = noVerticalPadding ? '0' : '16px'
  const horizontalPadding = noHorizontalPadding ? '0' : '16px'
  return (
    <div style={{ overflow: 'hidden', paddingTop: '16px'}}>
      <Paper sx={{
        margin: `0 ${horizontalMargin} ${bottomMargin} ${horizontalMargin}`,
        padding: `${verticalPadding} ${horizontalPadding}`,
        overflow: 'hidden'
      }}>
        {children}
      </Paper>
    </div>
  )
}
