import React from 'react'
import { Box, styled, Typography } from '@mui/material'
import { StandardTemplate } from '../../common/components/StandardTemplate'
import { licensesText } from './licenses-text'

interface Props {

}

const Content = styled(Box)`
  width: 100%;
  overflow: auto;
`

export function LicensesPage({}: Props) {
  return (
    <StandardTemplate title='Lizenzen'>
      <Content>
        <pre>
          {licensesText}
        </pre>
      </Content>
    </StandardTemplate>
  )
}


