import React, { useContext } from 'react'
import { QueueHeader } from './components/QueueHeader'
import MapLayout from '../../common/components/map/MapLayout'
import { Queue } from './model/queue'
import EditActions from './components/EditorActions'
import BezierMap from './components/BezierMap'
import useEditorActions from './hooks/useEditorActions'
import Login from '../user/Login'
import { getNavigationLocation, isNotPresent } from '../../common/utils'
import { AdminModeContext } from '../../app/User'
import { useEditQueue } from './hooks/useEditQueue'
import { useQueueIdUrlParam } from './hooks/useQueueIdUrlParam'

interface Props {
  queues: Queue[]
  updateQueue: (queue: Queue) => void
}


export default function QueueEditorPage({ queues, updateQueue }: Props) {
  const inAdminMode = useContext(AdminModeContext)
  const selectedQueueIndex = useQueueIdUrlParam(queues)

  if (!inAdminMode) return <Login redirectUrl={getNavigationLocation()} />
  if (isNotPresent(selectedQueueIndex)) return <></>


  return <QueueEditorView queues={queues}
                          updateQueue={updateQueue}
                          selectedQueueIndex={selectedQueueIndex} />
}

function QueueEditorView({
                           queues,
                           updateQueue,
                           selectedQueueIndex,
                         }: Props & { selectedQueueIndex: number }) {

  const {
    selectedQueue,
    addBezier,
    removeBezier,
    editCurve,
    setEditConfig,
    clearEditConfig,
    changeLocation,
    changeTitle,
    changeApiLineId,
    changeVelocity,
    changeVelocityOffset,
    alignTemplate,
    getAlignedByX,
    getAlignedByY,
    finishAlignment
  } = useEditQueue(queues, selectedQueueIndex)

  const {
    actionValue,
    setActions,
    onPressedMove,
    onMapClick,
    onDotDown,
    onDotUp,
    showDots
  } = useEditorActions(selectedQueue, editCurve, setEditConfig, clearEditConfig, removeBezier, changeLocation, getAlignedByX, getAlignedByY, finishAlignment)

  /*const actions = {
    [ActionValue.EDIT]: changeBeziers,
    [ActionValue.LOCATION]: changeLocation,
    [ActionValue.REMOVE]: removeBezier,
    [ActionValue.ALIGN_X]: aligner.getAlignedByX,
    [ActionValue.ALIGN_Y]: aligner.getAlignedByY,
  }*/


  /*const onPressedMove = useRef<VectorAction>()
  const onMapClick = useRef<VectorAction>()
  const onDotDown = useRef<MapDotAction>()
  const onDotUp = useRef<MapDotAction>()*/


  return (
    <MapLayout
      header={
        <QueueHeader queues={queues} selectedQueueId={selectedQueue.id} />
      }
      main={
        <BezierMap beziers={selectedQueue.path} location={selectedQueue.location}
                   onPressedMove={onPressedMove}
                   onMapClick={onMapClick}
                   onDotDown={onDotDown}
                   onDotUp={onDotUp}
                   showDots={showDots}
                   markedVector={alignTemplate}
        />
      }
      footer={
        <EditActions
          queue={selectedQueue}
          updateQueue={updateQueue}
          addBezier={addBezier}
          changeTitle={changeTitle}
          changeApiLineId={changeApiLineId}
          changeVelocity={changeVelocity}
          changeVelocityOffset={changeVelocityOffset}
          action={actionValue}
          setAction={setActions}
        />
      }
    />
  )
}

