export type DistanceWithT = { distance: number, t: number }
export type BezierWithDistanceAndT = { bezierIndex: number, distanceWithT: DistanceWithT }
export type CurvePoint = { bezierIndex: number, t: number }

export class TimedCurvePoint {
  constructor(
    readonly curvePoint: CurvePoint,
    readonly time: Date
  ) {
  }

  static fromTimedCurvePoint(timedCurvePoint: TimedCurvePoint) {
    return new TimedCurvePoint({ ...(timedCurvePoint.curvePoint) }, new Date(timedCurvePoint.time))
  }
}
