import { BezierCubic } from '../../../common/model/geometry/BezierCubic'
import { Vector2D } from '../../../common/model/geometry/Vector2D'
import { useRef } from 'react'
import { isNotPresent } from '../../../common/utils'
import { CurveVectorReference } from '../util/actions'

export default function useEditCurve() {

  const editConfigRef = useRef<CurveVectorReference>()

  function getEditedCurve(prevBeziers: BezierCubic[], moveDest: Vector2D) {
    const editConfig = editConfigRef.current
    if (isNotPresent(editConfig)) return undefined

    const { bezierIndex, vectorKey } = editConfig
    const editedBeziers = [...prevBeziers]

    switch (vectorKey) {
      case 'start':
        editedBeziers[bezierIndex] = editedBeziers[bezierIndex].copy({ start: moveDest })

        if (bezierIndex > 0) {
          editedBeziers[bezierIndex - 1] = editedBeziers[bezierIndex - 1].copy({ end: moveDest })
        }

        break
      case 'cp1':

        const snapToGradient = (vector: Vector2D) => {
          const start = editedBeziers[bezierIndex].start
          const d = start.subtract(editedBeziers[bezierIndex - 1].cp2).normalize()
          const a = vector.subtract(start)
          return start.add(d.scale(d.dot(a)))
        }

        editedBeziers[bezierIndex] = editedBeziers[bezierIndex].copy({ cp1: bezierIndex > 0 ? snapToGradient(moveDest) : moveDest })
        break
      case 'cp2':
        editedBeziers[bezierIndex] = editedBeziers[bezierIndex].copy({ cp2: moveDest })

        break
      case 'end':
        if (bezierIndex !== prevBeziers.length - 1) return prevBeziers
        editedBeziers[bezierIndex] = editedBeziers[bezierIndex].copy({ end: moveDest })
    }

    return editedBeziers
  }

  function setEditConfig(reference: CurveVectorReference) {
    editConfigRef.current = reference
  }

  function clearEditConfig() {
    editConfigRef.current = undefined
  }

  return {
    getEditedCurve,
    setEditConfig,
    clearEditConfig
  }
}
