import React, { ReactNode, useEffect, useRef, useState } from 'react'
import { styled } from '@mui/material'
import { DivMouseEvent } from '../../types'
import { Vector2D } from '../../model/geometry/Vector2D'


interface Props {
  children?: ReactNode
  onClick?: (vector: Vector2D, e: DivMouseEvent) => void
  onPressedMove?: (vector: Vector2D) => void
  position?: Vector2D
}

export function SimpleMap({ onClick, onPressedMove, children, position }: Props) {

  const [isMouseDown, setIsMouseDown] = useState(false)


  const mapWrapperRef = useRef<HTMLDivElement>(null)
  const imageWrapperRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const mapWrapper = mapWrapperRef.current
    const imageWrapper = imageWrapperRef.current

    if (!mapWrapper || !imageWrapper) return

    setTimeout(() => {
      if (!position) return

      const absoluteX = position.x / 100 * imageWrapper.offsetWidth
      const scrollLeft = absoluteX - mapWrapper.offsetWidth / 2

      const absoluteY = position.y / 100 * imageWrapper.offsetHeight
      const scrollTop = absoluteY - mapWrapper.offsetHeight / 2

      mapWrapper.scrollTo({
        left: scrollLeft,
        top: scrollTop,
        behavior: 'smooth'
      })
    }, 200)


  }, [position])


  const handleVectorEvent = (e: DivMouseEvent, handler: (vector: Vector2D, e: DivMouseEvent) => void) => {
    const map = e.currentTarget
    const container = map.parentElement
    const absoluteX = e.clientX - map.offsetLeft + (container?.scrollLeft ?? 0) + window.scrollX
    const absoluteY = e.clientY - map.offsetTop + (container?.scrollTop ?? 0) + window.scrollY

    const x = Math.round(absoluteX / map.offsetWidth * 10000) / 100
    const y = Math.round(absoluteY / map.offsetHeight * 10000) / 100

    handler(new Vector2D(x, y), e)
  }

  const handleClick = (e: DivMouseEvent) => {
    if (onClick) {
      handleVectorEvent(e, onClick)
    }
  }

  const handleMove = (e: DivMouseEvent) => {
    if (onPressedMove && isMouseDown) {
      handleVectorEvent(e, onPressedMove)
    }
  }

  return (
    <MapWrapper className='noScrollbar noSelect' ref={mapWrapperRef}>
      <ImageContainer ref={imageWrapperRef} className='noSelect'
                      onClick={onClick ? handleClick : undefined}
                      onMouseMove={onPressedMove ? handleMove : undefined}
                      onMouseDown={() => setIsMouseDown(true)}
                      onMouseUp={() => setIsMouseDown(false)}
                      onMouseLeave={() => setIsMouseDown(false)}
      >
        <img className='invertWhenDark noSelect' src='/res/images/map-north.png' alt=''
             draggable={false} />
        {children}
      </ImageContainer>
    </MapWrapper>
  )
}

const MapWrapper = styled('div')`
  height: 100%;
  width: 100%;
`


const ImageContainer = styled('div')`
  position: relative;
  width: 1000px;
  height: 813px;

  & > img {
    width: 100%;
  }
`
