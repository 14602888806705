import React from 'react'
import { StdLink } from '../../common/base'
import { StandardTemplate } from '../../common/components/StandardTemplate'

interface Props {

}

export function TermsPage({}: Props) {

  return (
    <StandardTemplate title='AGB'>
      Allgemeine Geschäftsbedingungen (AGB) für die Nutzung der App "Q-Line"

      <ol>

        <li>Geltungsbereich</li>
        Diese Allgemeinen Geschäftsbedingungen (AGB) gelten für die Nutzung der App "Q-Line" (im Folgenden "App"
        genannt), die von Julian Winter <StdLink href='/informationen/impressum'>siehe Impressum</StdLink> (im
        Folgenden "Anbieter" genannt) angeboten wird.


        <li>Leistungsumfang</li>
        Die App Q-Line zeigt die Länge der Schlangen in der KIT Mensa am Adenauerring an. Der Anbieter behält sich
        das
        Recht vor, den Leistungsumfang der App zu ändern oder einzuschränken.


        <li>Registrierung und Nutzerkonto</li>
        Die Nutzung der App erfordert keine Registrierung oder Erstellung eines Nutzerkontos.

        <li>Haftung</li>
        Der Anbieter haftet nicht für Schäden, die durch die Nutzung der App entstehen, es sei denn, es handelt sich
        um
        grob fahrlässiges oder vorsätzliches Verhalten des Anbieters.

        <li>Datenschutz</li>
        Der Anbieter verpflichtet sich, die geltenden Datenschutzbestimmungen einzuhalten. Die Nutzung der App
        erfordert
        keine Angabe von personenbezogenen Daten.

        <li>Verfügbarkeit</li>
        Der Anbieter bemüht sich, die App jederzeit verfügbar zu halten. Es kann jedoch nicht garantiert werden, dass
        die App jederzeit und ohne Unterbrechungen verfügbar ist.

        <li>Änderungen der AGB</li>
        Der Anbieter behält sich das Recht vor, diese AGB jederzeit zu ändern. Die geänderten AGB werden dem Nutzer
        bei
        der nächsten Nutzung der App angezeigt.

        <li>Schlussbestimmungen</li>
        Es gilt das Recht der Bundesrepublik Deutschland. Sollten einzelne Bestimmungen dieser AGB unwirksam sein oder
        werden, bleibt die Wirksamkeit der übrigen Bestimmungen hiervon unberührt. Gerichtsstand ist Karlsruhe.

      </ol>
    </StandardTemplate>
  )
}
