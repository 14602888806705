import React from 'react'
import { Box, styled } from '@mui/material'
import { Routing } from './routing/Routing'
import { MessageProvider } from '../common/view-systems/messages/MessageProvider'
import { FeedbackAnimationProvider } from '../common/view-systems/feedback-animation/FeedbackAnimationProvider'

const Main = styled(Box)`
  max-width: 1000px;
  margin: 0 auto;
  overflow: hidden;
  //position: relative;
  
  height: 100%;
  min-height: 100%;
  max-height: 100%;
`

const App = () => {


  return (
    <Main>
      <MessageProvider>
        <FeedbackAnimationProvider>
          <Routing />
        </FeedbackAnimationProvider>
      </MessageProvider>
    </Main>
  )
}

export default App
