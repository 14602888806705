import { StandardHeader } from '../../common/components/StandardHeader'
import React from 'react'
import { StandardLayout } from '../../common/components/StandardLayout'
import { LeaderboardContent } from './components/LeaderboardContent'

export function LeaderboardPage() {

  return (
    <StandardLayout
      header={<StandardHeader title='Leaderboard' showBack />}
      main={
        <>
          <LeaderboardContent />
        </>
      }
    />
  )
}