import React from 'react'
import { ChildrenProps } from '../types'
import { Box, styled } from '@mui/material'

const Wrapper = styled(Box)`
  padding: 8px;
  box-shadow: -1px 0 1px 0 #000000;
  position: relative;
  z-index: 100;
`

export function StandardFooter({ children }: ChildrenProps) {
  return (
    <Wrapper>
      <div className='safeBottomPadding'>
        {children}
      </div>
    </Wrapper>
  )
}
