import React from 'react'
import { StandardHeader } from './StandardHeader'
import { StandardContent } from './StandardContent'
import { ChildrenProps } from '../types'
import { StandardLayout } from './StandardLayout'

interface Props extends ChildrenProps {
  title: string
}

export function StandardTemplate({ children, title }: Props) {
  return (
    <StandardLayout
      header={<StandardHeader title={title} showBack />}
      main={
        <StandardContent>
          {children}
        </StandardContent>
      }
    />
  )
}
