import React from 'react'
import { Box, Divider, List, ListItemButton, ListItemText, styled, Typography } from '@mui/material'
import { StandardContent } from '../../../common/components/StandardContent'
import { useLeader } from '../hooks/useLeader'
import { Leader } from '../model/Leaderboard'
import { isNotPresent } from '../../../common/utils'

export function LeaderboardContent() {

  const leaderboard = useLeader()

  const currentWeek = getCurrentWeekDates()
  const currentKW = getCurrentCalendarWeek()

  if (isNotPresent(leaderboard)) return <></>

  return (
    <StandardContent noVerticalPadding noHorizontalPadding>

      <Box sx={{ display: 'flex', justifyContent: 'space-evenly', alignItems: 'flex-end' }} padding='8px'>
        <Typography variant='caption' fontSize='small'>{currentWeek}</Typography>
        <Typography variant='caption' fontSize='medium'> | </Typography>
        <Typography variant='caption' fontWeight='bold' fontSize='medium'> {currentKW} </Typography>
        <Typography variant='caption' fontSize='medium'> | </Typography>
        <Typography variant='caption' fontSize='small'>10:50 - 14:30 Uhr</Typography>
      </Box>

      <Divider sx={{ borderBottomWidth: 1.5, background: 'black' }} />

      <List>
        {leaderboard.leaders.map((leader, index) => (
          <span key={index}>
            <ListItemButton selected={index === leaderboard.userPlace} disableRipple>
              <ListItemText primary={<StyledLeaderboardItem leader={leader} index={index} />} />
            </ListItemButton>
            <Divider />
          </span>
        ))}


        {leaderboard.userPlace > leaderboard.leaders.length - 1 ?
          <>
            {leaderboard.userPlace > leaderboard.leaders.length && <><br /> <Divider /></>}
            <ListItemButton selected disableRipple>
              <ListItemText
                primary={<StyledLeaderboardItem leader={leaderboard.user} index={leaderboard.userPlace} />} />
            </ListItemButton>
          </>
          :
          <></>
        }

      </List>


    </StandardContent>
  )
}


const getCurrentWeekDates = (): string => {
  const currentDate = new Date()
  const firstDayOfWeek = currentDate.getDate() - currentDate.getDay() + 1
  const weekDates = []

  for (let i = 0; i < 5; i++) {
    const date = new Date(currentDate.setDate(firstDayOfWeek + i))
    const formattedDate = date.toLocaleDateString('de-DE', { day: '2-digit', month: '2-digit' })
    weekDates.push(formattedDate)
  }

  return weekDates[0] + ' - ' + weekDates[4]
}

const getCurrentCalendarWeek = (): string => {
  const today = new Date()
  const yearStart = new Date(today.getFullYear(), 0, 1)
  const weekNumber = Math.ceil(((today.getTime() - yearStart.getTime()) / 86400000 + yearStart.getDay() + 1) / 7)
  return `KW ${weekNumber}`
}


function StyledLeaderboardItem({ leader, index }: { leader: Leader, index: number }) {
  const LeaderboardItem = styled('div')`

    & > div.wrapper {
      display: flex;
      align-items: center;
      gap: 16px;

      & > div.name {
        flex-grow: 1;
      }

      & > div.entries {
        white-space: nowrap;
      }

    }
  `

  return (
    <LeaderboardItem>
      <div className='wrapper'>

        <div className='place'>
          <Typography variant='button'>{index + 1}</Typography>
        </div>

        <div className='name'>
          {leader.eaterName}
        </div>

        <div className='entries'>
          <Typography display='inline'>{leader.entries}</Typography>
          {leader.entries === 1 ?
            <Typography variant='caption'> Eintrag</Typography>
            :
            <Typography variant='caption'> Einträge</Typography>}
        </div>

      </div>
    </LeaderboardItem>
  )
}
