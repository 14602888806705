import { getDuration, Queue } from '../model/queue'
import { isNotPresent } from '../../../common/utils'
import { Chip } from '@mui/material'
import React from 'react'

export function QueueTimeChip({ queue }: { queue: Queue }) {
  function getColor(time: number): 'success' | 'warning' | 'error' {
    if (time > 15) return 'error'
    if (time > 10) return 'warning'
    return 'success'
  }

  const duration = getDuration(queue)

  if (isNotPresent(duration)) return <></>

  return <Chip size='small' variant='outlined' label={`${duration}min`}
               color={getColor(duration)} />
}