import React from 'react'
import { Box } from '@mui/material'

export function WelcomeContent() {
  return (
    <Box display='flex' justifyContent='center'>
      <img style={{ width: '100%', maxWidth: '500px' }} src='/res/images/onboarding-q-line.jpg' alt='' />
    </Box>
  )
}
