import { Vector2D } from '../../../common/model/geometry/Vector2D'
import { BezierCubic, BezierCubicData } from '../../../common/model/geometry/BezierCubic'

export enum ActionValue {
  EDIT,
  REMOVE,
  ALIGN_X,
  ALIGN_Y,
  LOCATION,
}

export type VectorAction = ((vector: Vector2D) => void) | undefined
export type MapDotAction =
  (mouseVector: Vector2D, reference: CurveVectorReference) =>
    void | undefined
export type ShowDotOption = 'all' | 'start' | 'none'

export class CurveVectorReference {
  constructor(
    readonly bezierIndex: number,
    readonly vectorKey: keyof BezierCubicData,
  ) {
  }

  getVector(beziers: BezierCubic[]) {
    return beziers[this.bezierIndex][this.vectorKey]
  }

  replaceVector(beziers: BezierCubic[], newVector: Vector2D) {
    const newBeziers = [...beziers]
    newBeziers[this.bezierIndex] =
      newBeziers[this.bezierIndex].copy({ [this.vectorKey]: newVector })
    return newBeziers
  }

  equals(other: any) {
    if (!(other instanceof CurveVectorReference)) {
      return false
    }
    return this.bezierIndex === other.bezierIndex && this.vectorKey === other.vectorKey
  }
}
