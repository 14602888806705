import { Classifier, Meal } from '../model/menu'
import { styled } from '@mui/material/styles'
import { isNotPresent, isPresent } from '../../../common/utils'
import * as React from 'react'
import { getDisplayCurrentEndWithSinceMinutes, Queue } from '../model/queue'
import { QueueTimeChip } from './QueueTimeChip'
import { Box, List, ListItem, ListItemButton, ListItemText, Tab, Tabs } from '@mui/material'
import useMenu from '../../../common/hooks/useMenu'
import { useNavigate } from 'react-router'


interface Props {
  queues: Queue[]
  selectedQueueId?: string
  toggleDrawer: (newOpen: boolean) => void
}

export function FoodDrawerTabMenu({ queues, selectedQueueId, toggleDrawer }: Props) {

  const menu = useMenu()

  const navigate = useNavigate()

  function navigateToQueue(apiLineId: string) {
    const queue = getQueue(apiLineId)
    if (isNotPresent(queue)) return
    toggleDrawer(false)

    const route = isPresent(selectedQueueId) ? `../${queue.id}` : queue.id
    navigate(route)
  }

  function getQueue(apiLineId: string) {
    const filteredQueues = queues.filter((queue) => queue.apiLineId === apiLineId)
    return filteredQueues.length === 1 ? filteredQueues[0] : undefined
  }


  const [value, setValue] = React.useState(0)

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }

  //console.log(menu && menu[0].date)


  const nextSevenDays: string[] = getNextDays()

  return (

    <>

      <TabWrapper>
        <Tabs
          value={value}
          onChange={handleChange}
          variant='scrollable'
          scrollButtons='auto'
          allowScrollButtonsMobile
          aria-label='scrollable force'
        >
          <Tab label='Heute' />
          {nextSevenDays.map(day => <Tab label={day} key={day} />)}

        </Tabs>
      </TabWrapper>

      <List>

        {isPresent(menu) && isPresent(menu[value]) && menu[value].lines?.map(line =>
          <ListItem key={line.id} disablePadding>
            <ListItemButton onClick={() => navigateToQueue(line.id)}>
              <ListItemText
                primary={<StyledMealHeader name={line.name} queue={getQueue(line.id)} value={value} />}
                secondary={
                  line.meals.map((meal, index) =>
                    <StyledMealItem meal={meal} key={index} />)
                }
              />
            </ListItemButton>
          </ListItem>)}

      </List>


    </>

  )
}

const TabWrapper = styled(Box)`
  position: sticky;
  top: -1px;
  z-index: 10;
  border-radius: 0;
  background: ${props => props.theme.palette.background.paper};

  display: flex;

`


function StyledMealHeader({ name, queue, value }: { name: string, queue?: Queue, value: number }) {

  const sinceMinutes = getDisplayCurrentEndWithSinceMinutes(queue?.currentEnd)?.sinceMinutes

  const MealHeader = styled('span')`
    display: block;
    margin-top: 8px;

    & > span.wrapper {
      display: flex;
      align-items: start;
      gap: 8px;

      & > span.name {
        flex-grow: 1;
      }
    }
  `

  return (
    <MealHeader>
      <span className='wrapper'>

        <span className='name'>
          {name}
        </span>

        {isPresent(queue) && value === 0 && isPresent(sinceMinutes) && <QueueTimeChip queue={queue} />}

      </span>
    </MealHeader>
  )
}


function StyledMealItem({ meal }: { meal: Meal }) {
  const MealItem = styled('span')`
    display: block;
    margin-top: 8px;

    & > span.wrapper {
      display: flex;
      align-items: start;
      gap: 8px;

      & > span.image {
        width: 20px;
        min-width: 20px;
        height: 20px;
        padding: 4px 4px 4px 0;
        text-align: center;

        & > img {
          height: 100%;
        }
      }

      & > span.name {
        flex-grow: 1;
      }

      & > span.price {
        white-space: nowrap;
      }
    }
  `

  return (
    <MealItem>
      <span className='wrapper'>

        <span className='image'>
          <MealIcon meal={meal} />
        </span>

        <span className='name'>
          {meal.name}
        </span>

        <span className='price'>
          {getPriceOutput(meal.price)}
        </span>

      </span>
    </MealItem>
  )
}

function getMealIcon(meal: Meal) {
  if (meal.classifiers.includes(Classifier.VEGAN)) {
    return 'https://www.sw-ka.de/layout/icons/veganes-gericht.svg' //'/res/images/vegan.png'
  }
  if (meal.classifiers.includes(Classifier.VEGGIE)) {
    return 'https://www.sw-ka.de/layout/icons/vegetarisches-gericht.svg'
  }
  if (meal.classifiers.includes(Classifier.PORK)) {
    return 'https://www.sw-ka.de/layout/icons/schweinefleisch.svg'
  }
  if (meal.classifiers.includes(Classifier.BEEF)) {
    return 'https://www.sw-ka.de/layout/icons/rindfleisch.svg'
  }
  if (meal.classifiers.includes(Classifier.FISH)) {
    return 'https://www.sw-ka.de/layout/icons/msc.svg'
  }
  return undefined
}


function MealIcon({ meal }: { meal: Meal }) {
  const Image = styled('img')`
    height: 100%;
  `
  const mealIcon = getMealIcon(meal)
  return isPresent(mealIcon) ? <Image src={mealIcon} alt='' /> : <></>
}

function getNextDays(): string[] {
  const days: string[] = []
  const weekdays: string[] = ['So.', 'Mo.', 'Di.', 'Mi.', 'Do.', 'Fr.', 'Sa.']
  const today: Date = new Date()

  for (let i = 1; i < 7; i++) {
    const date: Date = new Date()
    date.setDate(today.getDate() + i)
    const weekday: string = weekdays[date.getDay()]
    const day: number = date.getDate()
    const month: number = date.getMonth() + 1
    const formattedDate: string = `${weekday} ${day.toString().padStart(2, '0')}.${month.toString().padStart(2, '0')}`
    days.push(formattedDate)
  }

  return days
}

function getPriceOutput(price: string) {
  return (+price).toFixed(2).replace('.', ',') + ' €'
}
