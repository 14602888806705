import React, { createContext, ReactNode, useContext, useRef, useState } from 'react'
import { ChildrenProps } from '../../types'
import { Box, styled } from '@mui/material'
import { CheckCircleOutline } from '@mui/icons-material'
import { Howl } from 'howler'
import { valueOrDefault } from '../../utils'

const FeedbackAnimationContext = createContext<(content?: ReactNode) => void>(() => undefined)

export function FeedbackAnimationProvider({ children }: ChildrenProps) {
  const contentRef = useRef<ReactNode>(<></>)
  const [show, setShow] = useState(false)

  function showFeedback(content?: ReactNode) {
    const sound = new Howl({ src: ['/res/audio/pling.mp3'] })
    sound.play()

    contentRef.current = (valueOrDefault(content, <CheckCircleOutline sx={{ fontSize: '100vw' }} color='success' />))

    setShow(true)
    setTimeout(() => {
      setShow(false)
    }, 1000)
  }

  return (
    <FeedbackAnimationContext.Provider value={showFeedback}>
      {children}

      {show && (
        <AnimationWrapper>
          {contentRef.current}
        </AnimationWrapper>
      )}

    </FeedbackAnimationContext.Provider>
  )
}

export function useFeedbackAnimation() {
  return useContext(FeedbackAnimationContext)
}

const AnimationWrapper = styled(Box)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 5000;

  opacity: 0;

  display: flex;
  align-items: center;
  justify-content: center;


  animation: animate;
  animation-duration: 1000ms;


  @keyframes animate {
    0% {
      transform: scale(1);
      opacity: 0;
    }
    35% {
      transform: scale(0.5);
      opacity: 1;
    }
    65% {
      transform: scale(0.5);
      opacity: 1;
    }
    100% {
      transform: scale(1);
      opacity: 0;
    }
  }

`

