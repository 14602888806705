import { Vector2D } from './Vector2D'
import { getBestFit, pairwise } from '../../array-utils'
import { byNumber } from '../../utils'

type DistanceWithT = { distance: number, t: number }
type PreCalculation = { t: number, vector: Vector2D, length: number }

export interface BezierCubicData {
  readonly start: Vector2D,
  readonly cp1: Vector2D,
  readonly cp2: Vector2D,
  readonly end: Vector2D,
}

export class BezierCubic implements BezierCubicData {

  private static readonly steps = 1000
  private readonly preCalculations: PreCalculation[]

  constructor(
    readonly start: Vector2D,
    readonly cp1: Vector2D,
    readonly cp2: Vector2D,
    readonly end: Vector2D,
  ) {
    this.preCalculations = this.getPreCalculations(BezierCubic.steps)
  }

  static fromBezier(bezier: BezierCubic) {
    return new BezierCubic(
      Vector2D.fromVector(bezier.start),
      Vector2D.fromVector(bezier.cp1),
      Vector2D.fromVector(bezier.cp2),
      Vector2D.fromVector(bezier.end)
    )
  }

  private static getWrongTErrorMessage(t: number) {
    return `t should be in interval [0;1], t = ${t} given`
  }

  copy({
         start = this.start,
         cp1 = this.cp1,
         cp2 = this.cp2,
         end = this.end
       }) {
    return new BezierCubic(start, cp1, cp2, end)
  }

  toData(): BezierCubicData {
    return {
      start: this.start,
      cp1: this.cp1,
      cp2: this.cp2,
      end: this.end
    }
  }

  getClosestDistanceWithT(vector: Vector2D): DistanceWithT {
    let closestDistance = Infinity
    let closestT = 0

    for (let i = 0; i < BezierCubic.steps; i++) {
      const distance = vector.getDistance(this.preCalculations[i].vector)
      if (distance < closestDistance) {
        closestDistance = distance
        closestT = i / BezierCubic.steps
      }
    }
    return { distance: closestDistance, t: closestT }
  }

  getPreCalculations(steps: number): PreCalculation[] {
    const preCalculations: PreCalculation[] = []
    for (let step = 0; step < steps; step++) {
      const t = step / steps
      const vector = this.at(t)
      let length = 0

      if (t > 0) {
        const prevT = (step - 1) / steps
        const prevVector = this.at(prevT)
        length = this.getLengthInArrayTo(prevT, preCalculations) + vector.getDistance(prevVector)
      }

      preCalculations.push({ t, vector, length })
    }
    return preCalculations
  }

  at(t: number) {
    if (t < 0 || t > 1) throw Error(BezierCubic.getWrongTErrorMessage(t))

    return this.atRecursive(t, [this.start, this.cp1, this.cp2, this.end])[0]
  }

  getLength() {
    return this.getLengthTo(1)
  }

  getLengthTo(t: number) {
    return this.getLengthInArrayTo(t, this.preCalculations)
  }

  split(t: number): [BezierCubic, BezierCubic] {
    const a = this.start
    const b = this.cp1
    const c = this.cp2
    const d = this.end

    const e = a.lerp(b, t)
    const f = b.lerp(c, t)
    const g = c.lerp(d, t)
    const h = e.lerp(f, t)
    const j = f.lerp(g, t)
    const k = h.lerp(j, t)

    return [new BezierCubic(a, e, h, k), new BezierCubic(k, j, g, d)]
  }

  splitMultiple(tValues: number[]): BezierCubic[] {
    // Ensure tValues are in ascending order
    tValues.sort(byNumber)

    // Initialize variables
    const curves: BezierCubic[] = []
    let lastT = 0
    let lastCurve: BezierCubic = this

    // Iterate over each t value, splitting the curve and saving the new curves
    for (const t of tValues) {
      if (t <= lastT || t >= 1) {
        continue // Ignore invalid or duplicate t values
      }

      // Split the curve at this t value
      const [left, right] = lastCurve.split(t)
      curves.push(left)
      lastCurve = right
      lastT = t
    }

    // Add the last curve to the array
    curves.push(lastCurve)

    return curves
  }

  private getLengthInArrayTo(t: number, array: PreCalculation[]) {
    if (t < 0 || t > 1) throw Error(BezierCubic.getWrongTErrorMessage(t))
    return getBestFit(t, array, item => item.t).length
  }

  private atRecursive(t: number, vectors: Vector2D[]): Vector2D[] {
    if (vectors.length === 1)
      return vectors

    const newVectors = pairwise(vectors,
      (current, next) => current.lerp(next, t))

    return this.atRecursive(t, newVectors)
  }
}
