import React from 'react'
import User from '../User'
import { Route, Routes } from 'react-router-dom'
import { CookiesProvider } from 'react-cookie'
import Login from '../../modules/user/Login'
import { QueueRouting } from './QueueRouting'
import { LeaderboardPage } from '../../modules/leaderboard/LeaderboardPage'

interface Props {

}

export function UserRouting({}: Props) {


  return (
    <>
      <CookiesProvider>
        <User>
          <Routes>

            <Route path='/*' element={<QueueRouting />} />

            <Route path='leaderboard' element={<LeaderboardPage />} />

            <Route path='login' element={<Login />} />

          </Routes>
        </User>
      </CookiesProvider>
    </>

  )
}
