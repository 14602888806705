import React from 'react'
import { Button, TextField, ToggleButton, ToggleButtonGroup, Toolbar } from '@mui/material'
import { Add, Delete, Edit, MoreHoriz, MoreVert, Place } from '@mui/icons-material'
import { Queue } from '../model/queue'
import { ActionValue } from '../util/actions'

interface Props {
  queue: Queue,
  changeTitle: (title: string) => void,
  changeApiLineId: (apiLineId: string) => void,
  addBezier: () => void,
  action?: ActionValue,
  setAction: (action?: ActionValue) => void,
  updateQueue: (queue: Queue) => void,
  changeVelocity: (velocity: number) => void
  changeVelocityOffset: (velocityOffset: number) => void
}

function EditorActions({
                         queue,
                         changeTitle,
                         addBezier,
                         action,
                         setAction,
                         updateQueue,
                         changeApiLineId,
                         changeVelocity,
                         changeVelocityOffset
                       }: Props) {
  return (
    <Toolbar sx={{ gap: '16px' }}>
      <TextField label='Title'
                 size='small'
                 sx={{ flexGrow: 1 }}
                 variant='outlined'
                 value={queue.title}
                 onChange={e => changeTitle(e.target.value)} />
      <TextField label='Api Line Id'
                 size='small'
                 variant='outlined'
                 value={queue.apiLineId ?? ''}
                 onChange={e => changeApiLineId(e.target.value)} />
      <TextField label='Velocity'
                 size='small'
                 variant='outlined'
                 type='number'
                 value={queue.velocity}
                 onChange={e => changeVelocity(+e.target.value)} />
      <TextField label='Velocity Offset'
                 size='small'
                 variant='outlined'
                 type='number'
                 value={queue.velocityOffset}
                 onChange={e => changeVelocityOffset(+e.target.value)} />
      <Button variant='outlined' onClick={addBezier}>
        <Add />
      </Button>

      <ToggleButtonGroup
        size='small'
        color='primary'
        value={action}
        exclusive
        onChange={(_, value) => setAction(value)}
        aria-label='text alignment'
      >
        <ToggleButton value={ActionValue.EDIT}>
          <Edit />
        </ToggleButton>
        <ToggleButton value={ActionValue.REMOVE}>
          <Delete />
        </ToggleButton>
        <ToggleButton value={ActionValue.ALIGN_Y}>
          <MoreHoriz />
        </ToggleButton>
        <ToggleButton value={ActionValue.ALIGN_X}>
          <MoreVert />
        </ToggleButton>
        <ToggleButton value={ActionValue.LOCATION}>
          <Place />
        </ToggleButton>
      </ToggleButtonGroup>

      <Button variant='contained' sx={{ flex: '1 0 0' }}
              onClick={() => {
                setAction(undefined)
                updateQueue(queue)
              }}>
        Save
      </Button>
    </Toolbar>
  )
}

export default EditorActions
