import React from 'react'
import { getDisplayCurrentEndWithSinceMinutes, Queue } from '../model/queue'
import { isPresent } from '../../../common/utils'
import { CurveUtils } from '../../../common/model/geometry/CurveUtils'
import { QueueDraw } from './QueueDraw'
import { useTheme } from '@mui/material'

interface Props {
  queues: Queue[]
}

export function OtherQueuesDraw({ queues }: Props) {
  return (
    <>
      {
        queues.map(queue => (
          <OtherQueueDraw key={queue.id} queue={queue} />
        ))
      }
    </>
  )
}


function OtherQueueDraw({ queue }: { queue: Queue }) {

  const theme = useTheme()
  const basicCurveColor = theme.palette.grey['400']
  const notQueuedCurveColor = theme.palette.grey['400']

  const beziers = queue.path
  const location = queue.location

  const currentEndWithSinceMinutes = getDisplayCurrentEndWithSinceMinutes(queue.currentEnd)

  const endT = CurveUtils.getMaxCurveT(queue.path)

  const [colorMarks, isSecondary] = (() => {
    if (isPresent(currentEndWithSinceMinutes)) {
      const queuedT = CurveUtils.getCurveTFrom(currentEndWithSinceMinutes.currentEnd.curvePoint)
      return [{ [queuedT]: basicCurveColor }, false]
    }
    return [{ [endT]: notQueuedCurveColor }, true]
  })()

  return (
    <QueueDraw
      beziers={beziers} colorMarks={colorMarks} isSecondary={isSecondary}
      location={location} locationColor={basicCurveColor} />
  )
}
