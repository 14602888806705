import { Box, Paper, styled } from '@mui/material'
import React, { ReactNode } from 'react'
import { DivMouseEvent } from '../../types'
import { Vector2D } from '../../model/geometry/Vector2D'
import classNames from 'classnames'
import { isNotPresent } from '../../utils'

type MouseEvent = (mouseVector: Vector2D, e: DivMouseEvent) => void

interface EventProps {
  onMouseDown?: MouseEvent
  onMouseUp?: MouseEvent
}

interface MapVectorProps extends EventProps {
  vector: Vector2D
  align?: 'center' | 'end'
  children: ReactNode
}

const MapVectorBox = styled(Box)`
  position: absolute;
  overflow: visible;
  display: flex;
  justify-content: center;
  width: 2px;
  height: 2px;
`

const MapVector = ({ vector, align, children, onMouseDown, onMouseUp }: MapVectorProps) => {
  if (!align) align = 'center'

  const getCoordinates = (e: DivMouseEvent) => {
    return new Vector2D(e.nativeEvent.offsetX, e.nativeEvent.offsetY)
  }

  const handleMouseDown = (e: DivMouseEvent) => {
    if (!onMouseDown) return
    onMouseDown(getCoordinates(e), e)
  }

  const handleMouseUp = (e: DivMouseEvent) => {
    if (!onMouseUp) return
    onMouseUp(getCoordinates(e), e)
  }

  return (
    <MapVectorBox
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
      sx={{
        left: `calc(${vector.x}% - 1px)`,
        top: `calc(${vector.y}% - 1px)`,
        alignItems: align
      }}>
      {children}
    </MapVectorBox>
  )
}

interface MapDotProps extends EventProps {
  vector: Vector2D
  backgroundColor: string
  borderColor?: string
  size?: 'small' | 'large'
}

const DotDiv = styled('div')`
  width: 8px;
  height: 8px;
  flex: 1 0 8px;
  border-radius: 50%;
  overflow: hidden;
  position: relative;

  &.hasBorder {
    border: 2px solid black;
  }

  &.large {
    width: 16px;
    height: 16px;
    flex: 1 0 16px;
  }
`

export const MapDot = ({ vector, backgroundColor, borderColor, size, onMouseDown, onMouseUp }: MapDotProps) => {
  const dotDivClasses = classNames({
    hasBorder: borderColor,
    large: size === 'large'
  })

  return (
    <MapVector vector={vector} onMouseDown={onMouseDown} onMouseUp={onMouseUp}>
      <DotDiv className={dotDivClasses} style={{ background: backgroundColor, borderColor }} />
    </MapVector>
  )
}


interface MapIconProps {
  vector: Vector2D | undefined
  children: ReactNode
  wrap?: boolean
  align?: 'center' | 'end'
}

const IconWrapper = styled(Paper)`
  overflow: hidden;
  position: relative;
  width: 32px;
  height: 32px;
  flex: 1 0 32px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const MapIcon = ({ vector, children, wrap, align }: MapIconProps) => {
  if (isNotPresent(vector)) return <></>
  if (!wrap) wrap = false

  return (
    <MapVector vector={vector} align={align}>
      {
        wrap ? <IconWrapper>{children}</IconWrapper> : children
      }
    </MapVector>
  )
}


/*
* `
  overflow: hidden;
  position: absolute;
  top: -50%;
  left: -50%;
  background-color: ;
`
* */



