import React from 'react'
import { Typography } from '@mui/material'
import { StdLink } from '../../../common/base'
import { StandardContent } from '../../../common/components/StandardContent'

export function AgreeContent() {
  return (
    <StandardContent noVerticalPadding>
      <Typography padding='16px'>
        Um diese App zu verwenden,
        musst du unseren <StdLink href='/informationen/datenschutz'>Datenschutzbedingungen</StdLink> und <StdLink
        href='/informationen/agb'>AGB</StdLink> zustimmen.
        <br />
        Insbesondere verwenden wir technisch notwendige Cookies,
        Näheres dazu findest du in unseren <StdLink href='/informationen/datenschutz'>Datenschutzbedingungen</StdLink>.
      </Typography>
    </StandardContent>

  )
}
