import React from 'react'
import { StdLink } from '../../common/base'
import { StandardTemplate } from '../../common/components/StandardTemplate'

interface Props {

}

export function PrivacyPage({}: Props) {

  return (
    <StandardTemplate title='Datenschutz'>
      Datenschutzerklärung für die Nutzung der App "Q-Line"

      <ol>


        <li>Allgemeines</li>
        Die App "Q-Line" (im Folgenden "App" genannt) wird von Julian
        Winter <StdLink href='/informationen/impressum'>siehe Impressum</StdLink> (im Folgenden "Anbieter"
        genannt) bereitgestellt. Diese Datenschutzerklärung informiert Sie darüber, welche personenbezogenen Daten bei
        der Nutzung der App erhoben, verarbeitet und genutzt werden.

        <li>Erhebung, Verarbeitung und Nutzung von Daten</li>
        Bei der Nutzung der App werden keine personenbezogenen Daten erhoben, verarbeitet oder genutzt.

        <li>Protokolldateien</li>
        Die App kann automatisch Informationen in Protokolldateien erfassen, die Ihr Browser automatisch an uns
        übermittelt. Diese Informationen umfassen IP-Adresse, Browsertyp und -version, Betriebssystem, Referrer-URL und
        Uhrzeit der Serveranfrage. Diese Daten werden ausschließlich zur Analyse der Nutzung der App verwendet und nicht
        mit anderen Datenquellen zusammengeführt.

        <li>Cookies</li>
        Die App verwendet Cookies, um Ihnen ein optimales Nutzererlebnis zu bieten. Cookies sind kleine Textdateien, die
        auf Ihrem Gerät gespeichert werden, wenn Sie unsere App besuchen. Dabei unterscheiden wir zwischen technisch
        notwendigen Cookies und Cookies für Analysezwecke.
        Technisch notwendige Cookies sind für die ordnungsgemäße Funktion der App unerlässlich. Diese Cookies werden
        automatisch beim Besuch unserer App gesetzt. Wir verwenden sie, um die Enden der Schlangen präziser berechnen zu
        können und Benutzereinstellungen zu speichern. Ohne
        diese Cookies können wir Ihnen bestimmte Funktionen nicht bereitstellen.
        Cookies für Analysezwecke nutzen wir nicht auf unserer Webseite. Wir setzen keine Cookies von Drittanbietern und
        verwenden auch keine Social-Media-Plugins, um Ihr Surfverhalten zu analysieren.
        Wenn Sie unsere App nutzen, erklären Sie sich mit dem Einsatz von technisch notwendigen Cookies einverstanden.
        Die Speicherung dieser Cookies erfolgt aufgrund unseres berechtigten Interesses (Art. 6 Abs. 1 lit. f DSGVO) an
        der ordnungsgemäßen Funktion der App.
        Ihre Einwilligung für den Einsatz von technisch notwendigen Cookies können Sie jederzeit widerrufen. Hierzu
        können Sie in Ihrem Browser die Einstellungen für Cookies ändern und bereits gesetzte Cookies löschen. Bitte
        beachten Sie jedoch, dass dadurch bestimmte Funktionen unserer App möglicherweise nicht mehr vollständig zur
        Verfügung stehen.

        <li>Externe Links</li>
        Die App kann Links zu externen Websites enthalten. Der Anbieter hat keinen Einfluss auf die Datenschutzpraktiken
        dieser Websites und übernimmt keine Verantwortung für deren Datenschutzpraktiken.

        <li>Datensicherheit</li>
        Der Anbieter ergreift angemessene technische und organisatorische Maßnahmen, um Ihre Daten vor Verlust,
        Missbrauch, unberechtigtem Zugriff, Offenlegung oder Änderung zu schützen.

        <li>Auskunfts- und Widerrufsrecht</li>
        Sie haben das Recht, jederzeit Auskunft über die bei uns gespeicherten personenbezogenen Daten zu erhalten. Sie
        können auch jederzeit die Löschung Ihrer Daten verlangen. Hierfür wenden Sie sich bitte an den Anbieter.

        <li>Änderungen der Datenschutzerklärung</li>
        Der Anbieter behält sich das Recht vor, diese Datenschutzerklärung jederzeit zu ändern. Die geänderte
        Datenschutzerklärung wird dem Nutzer bei der nächsten Nutzung der App angezeigt.

        <li>Kontaktaufnahme</li>
        Bei Fragen oder Bedenken bezüglich dieser Datenschutzerklärung oder der App im Allgemeinen wenden Sie sich bitte
        an den Anbieter unter den im Impressum angegebenen Kontaktdaten.
      </ol>

      Stand: 01.05.2023
    </StandardTemplate>
  )
}
