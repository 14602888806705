import React from 'react'
import { Box, IconButton, styled } from '@mui/material'
import { useNavigate } from 'react-router'
import { ArrowBackIosNew } from '@mui/icons-material'
import { StandardBar } from './StandardBar'

interface Props {
  title: string
  backUrl?: string
  showBack?: boolean
}

const Wrapper = styled(Box)`
  padding: 8px;
  margin-bottom: 16px;
  box-shadow: 1px 0 1px 0 #000000;
  position: relative;
  z-index: 100;
`

export function StandardHeader({ title, backUrl, showBack }: Props) {
  const navigate = useNavigate()

  function handleBack() {
    const url = backUrl ?? -1
    // @ts-ignore
    navigate(url)
  }

  // @ts-ignore
  return (
    <Wrapper>
      <StandardBar
        leftSlot={
          Boolean(showBack) && (<IconButton onClick={handleBack}>
            <ArrowBackIosNew />
          </IconButton>)
        }
        text={title}
        variant='h6'
      />
    </Wrapper>
  )
}
