import React, { createContext } from 'react'
import useUser from '../common/hooks/useUser'
import { ChildrenProps } from '../common/types'
import axios, { AxiosInstance } from 'axios'
import { UserCredentials } from '../modules/user/model/User'

type SecureApiContextType = {
  secureApi: AxiosInstance,
  login: (userCredentials: UserCredentials) => Promise<void>,
  logout: () => void,
  eaterName: string
}

export const AdminModeContext = createContext(false)

export const SecureApiContext = createContext<SecureApiContextType>({
  secureApi: axios,
  login: () => Promise.any([]),
  logout: () => {
  },
  eaterName: ''
})

export default function User({ children }: ChildrenProps) {
  const { secureApi, login, logout, inAdminMode, eaterName } = useUser()

  return (
    <AdminModeContext.Provider value={inAdminMode}>
      {secureApi && (
        <SecureApiContext.Provider value={{ secureApi, login, logout, eaterName }}>
          {children}
        </SecureApiContext.Provider>
      )}
    </AdminModeContext.Provider>

  )
}
