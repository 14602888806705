import React from 'react'
import { StandardTemplate } from '../../common/components/StandardTemplate'
import { Link, Typography } from '@mui/material'
import { StdLink } from '../../common/base'

interface Props {

}

const moduleLink = `https://www.wiwi.kit.edu/mhbDetails.php?type=brick&id=688EB3CF-987E-4E7E-A3F0-817813C5CBA4&productid=1895ED2C-659E-484B-82A0-DFE5BC7A8714`

export function AboutPage({}: Props) {
  return (
    <StandardTemplate title='Über uns und das Projekt'>
      <img style={{ width: '100%', }} src='/res/images/q-line-logo.png' alt='' />

      <Typography>
        Willkommen bei Q-Line! <br /><br />

        Q-Line ist ein Tool, um die Schlangenlängen in der Mensa anzuzeigen. Die Idee ist, dass man dadurch besser
        abschätzen kann,
        wann man in die Mensa gehen möchte - schließlich möchte man ja möglichst kurz in der Schlange
        anstehen. <br /><br />

        Q-Line ist im Rahmen des Moduls <Link target='_blank' href={moduleLink}>Spezialveranstaltung
        Wirtschaftsinformatik</Link> entstanden. Ziel dieser Veranstaltung ist es, ein praxisnahes Projekt
        durchzuführen, welches sowohl Anteile der Informatik wie auch der Wirtschaft beinhaltet. Wir haben uns
        ausgesucht, diese Plattform zu entwickeln und zu vermarkten. Besonders interessant dabei ist, dass anders als
        bei den meisten geläufigen Plattformen die Nutzung ohne Registrierung und Login erfolgt. Insbesondere ist es
        dadurch jedem möglich, anonym Schlangenlängen einzutragen - auch wenn man versehentlich oder böswillig
        falsche Einträge machen kann. Wir fragen uns daher, inwiefern wir sicherstellen können, dass die Daten möglichst
        korrekt sind und wie wir möglichst viele Benutzer für die Plattform begeistern können.<br /><br />

        Wir freuen uns über jeden Schlangen-Ende-Eintrag, der gemacht wird. Mit wenigen Klicks könnt ihr dadurch unser
        Projekt unterstützen.
        Aber: bitte gebt euch Mühe beim Eintragen, tragt nur möglichst korrekte Schlangen-Enden ein.
        Die Plattform basiert auf <b>Vertrauen</b> und wir hoffen sehr, dass dieses Konzept funktioniert.
        Teilt gerne die App mit euren Freunden, entsprechende Funktionalitäten findet ihr in
        den <StdLink href='/informationen'>Informationen</StdLink>. <br /><br />

        Vielen Dank für eure Unterstützung! <br /><br />

        Niklas und Julian


        {/*<br />
        Q-Line ist ein Projekt, das in Kooperation mit dem FZI (Forschungszentrum Informatik) entstanden ist.
        Ziel des Projekts ist es, die Schlangenlänge in der KIT Mensa am Adenauerring abzubilden.<br />
        <br />
        Wir sind zwei Informatik Studenten die es leid waren immer zur falschen Zeit in der Mensa zu sein. Wir wollten
        von Zuhause, aus der Vorlesung oder der Bib wissen wie lang die Mensaschlangen aktuell sind!<br />
        <br />
        Q-Line ist eine Platform die nur funktioniert wenn möglichst viele teilnehmen. Also nimmt euch doch bitte die
        paar Sekunden Zeit - vllt. während ihr aussucht zu welcher Linie ihr wollt, oder wenn ihr euch gerade anstellt -
        und tragt die aktuellen Schlangenlängen ein.<br />
        <br />
        Vielen Dank!<br />
        Das Q-Line-Team<br />
        <br />*/}
      </Typography>


    </StandardTemplate>
  )
}
