import React from 'react'
import { StandardTemplate } from '../../common/components/StandardTemplate'
import { Link, styled, Typography } from '@mui/material'
import { serverBaseUrl } from '../../app/base'

interface Props {

}


export function DeveloperPage({}: Props) {
  return (
    <StandardTemplate title='Entwickler'>

      <Typography variant='h4'>API Dokumentation</Typography>
      <p>Auf Nachfrage haben wir für das Abfragen der Schlangen-Enden ein API für Dritte erstellt.</p>

      <br />

      <Typography variant='h6'>Hinweise</Typography>
      <p>(*) Die App läuft seit dem 23. Mai 2023 in Production.</p>
      <p>(**) Zeitangaben sind immer basierend auf der UTC-Zeitzone (deutsche Zeitzone ist +2 Stunden).</p>

      <br />

      <Typography variant='h6'>Endpoints</Typography>
      <p>Basis-URL: <code>{serverBaseUrl}/api/v1</code></p>

      <br />

      <p><code>/overview</code></p>
      Gibt einen Überblick über die Performance der App.
      <BulletList>
        <li><code>days</code>: Anzahl der Tage, die die App bereits in Production läuft (*)</li>
        <li><code>entries</code>: Anzahl der Einträge, die zwischen 08:50 und 12:30 Uhr (**) gemacht wurden</li>
        <li><code>users</code>: Anzahl der verschiedenen Benutzer, die bereits mind. 1 Eintrag getätigt haben</li>
      </BulletList>

      <br />

      <p><code>/queue-ends</code></p>
      Gibt alle Schlangen-Enden, die getätigt wurden, seit die App in Production läuft (*)
      <BulletList>
        <li><code>queueTitle</code>: Name der Linie (bspw. [kœri]werk)</li>
        <li><code>time</code>: ISO-Timestamp, zu dem das Schlangenende eingetragen wurde (**)</li>
        <li>
          <code>length</code>: Länge der Schlange, die durch das eingetragene Schlangen-Ende spezifiziert
          wird. <b>Achtung:</b> Dies ist die tatsächlich eingetragene Länge. Die Länge, die in der App angezeigt wird,
          wird i.d.R. aus mehreren eingetragenen Schlangen-Enden berechnet.
        </li>
      </BulletList>

      <br />
      <br />

      <p>
        <Link href='mailto:feedback@q-line.io?subject=Frage%20zur%20API'>
          Wendet euch bei Fragen gerne an uns!
        </Link>
      </p>
    </StandardTemplate>
  )
}

const BulletList = styled('ul')`
  li {
    margin-bottom: 8px;
    line-height: 1.4rem;
  }
`
